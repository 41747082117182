@import "../../mixins/global";

.EarlyAccessTooltip {
  &__container {
    position: absolute;
    width: 290px;
    height: fit-content;
    top: 0;
    right: 0;
    transition: 1s;
    transform: translate(-40px, -100%);
    background-color: white;
    border-radius: 4px;
    padding: 24px 29px 29px 24px;
    box-shadow: 0px 0px 2.94545px rgb(40 41 61 / 4%),
      0px 5.89091px 11.7818px rgb(96 97 112 / 16%);

    @include rxc-mobile-only {
      top: unset;
      transform: translate(-40px, calc(-100% - 20px));
    }

    &.invisible {
      opacity: 0;
    }

    &.gone {
      display: none;
    }

    &__arrow {
      position: absolute;
      right: 0;
      width: 0;
      height: 0;
      bottom: 0;
      border-left: 13px solid transparent;
      border-right: 13px solid transparent;
      border-top: 13px solid white;
      transform: translateY(90%);
      -webkit-filter: drop-shadow(0px 15px 7px rgba(96, 97, 112, 0.1));
      filter: drop-shadow(0px 15px 7px rgba(96, 97, 112, 0.1));
    }

    &__body {
      font: 12px $rxc-costa-Interstate-font, Verdana, Arial, sans-serif;
      color: #222222;
    }

    &__close {
      position: absolute;
      cursor: pointer;
      top: 5px;
      right: 5px;
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='8' fill='%23767676'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.9999 13.0601L15.0554 16.1156L16.1161 15.0549L13.0606 11.9994L16.1165 8.94347L15.0558 7.88281L11.9999 10.9387L8.94396 7.88281L7.8833 8.94347L10.9392 11.9994L7.8837 15.0549L8.94436 16.1156L11.9999 13.0601Z' fill='white'/%3E%3C/svg%3E%0A");
    }
  }
}
