@import "../../mixins/global";

.StepPrice {
  & {
    display: flex;
    justify-content: flex-end;
  }

  &__container {
    display: flex;
    text-align: center;
    align-items: baseline;

    &__insuranceLoading {
      align-items: center;
    }

    &__insuranceText {
      font: 16px $rxc-costa-Interstate-font;
      color: $rxc-green-costa !important;
    }

    &__inner {
      font: 12px $rxc-costa-Interstate-font;
      color: $rxc-fontColor-costa !important;
      display: flex;
      align-items: baseline;

      @include rxc-mobile-only {
        align-items: center;
      }

      &__strikePrice {
        font-size: 12px;
        font-family: $rxc-costa-Interstate-font;
        color: $rxc-fontColor-costa !important;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 16px;
        letter-spacing: 0.16px;
        text-align: right;
        text-decoration-line: line-through;

        @include rxc-desktop-only {
          margin-right: 6px;
          margin-top: 5px;
        }

        @include rxc-mobile-only {
          position: relative;
          margin-top: 0px !important;
        }
      }
      // &__strikePrice:before {
      //     content: "+";
      //     margin-right: 1px;
      // }
      &__price {
        font-family: $rxc-costa-Interstate-font;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 24px;
        letter-spacing: normal;
        text-align: right;
        color: #182446 !important;

        @include rxc-mobile-only {
          display: inline-flex;
          margin-left: 4px;
        }
      }
      &__free {
        font-family: $rxc-costa-Interstate-font;
        font-size: 14px;
        font-weight: bolder;
        letter-spacing: normal;
        text-align: right;
        color: $rxc-fontColor-costa !important;
      }
      &__green {
        color: #247534 !important;
      }
      &__GVPLabel {
        margin-right: 8px;
        font: 12px $rxc-costa-Interstate-font;
      }
      &__startingAtLabel {
        margin-right: 8px;
        font: 12px $rxc-costa-Interstate-font;
      }
      &__labelContainer {
        display: flex;
        align-items: baseline;
      }
    }
  }
  &__vatIncluded {
    position: absolute;
    transform: translateY(22px);
    font: 12px $rxc-costa-Interstate-font;
    font-weight: normal;
    color: #6d6d6d;
  }
}
