@import "../../mixins/global";

.ProductContainer {
  font-family: $rxc-costa-Interstate-font;

  &__fallbackContainer {
    display: flex;
    align-content: center;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 285px;
    margin: auto;
    padding: 8px 16px;
    border: solid 1px #dde5ed;
    box-sizing: border-box;
    align-items: center;

    @include rxc-mobile-only {
      justify-content: unset;
      min-width: unset;
      padding: 8px 16px;
      margin-bottom: 1rem;
    }
  }

  &__fallbackImage {
    width: auto;
    max-width: 40px;
    height: 40px;
    min-height: 40px;
    margin: 0 17px 0 0;
  }

  &__container {
    @include rxc-mobile-only {
      display: none;
    }

    position: relative;
    flex: 1 1 50%;
    padding: 0px 0 33px 40px;
  }

  &__productImageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: 20px;

    &__productImageContent {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      position: relative;

      &__productImage {
        width: 70%;
        max-width: 680px;
        object-fit: cover;
        margin-bottom: 2rem;

        @include rxc-mobile-only {
          width: auto;
          max-height: 150px;
        }

        &__fallbackMessageTitle {
          font-family: $rxc-costa-Interstate-font;
          font-size: 12px;
          font-weight: bold;
          text-align: left;
          line-height: 15px;

          @include rxc-mobile-only {
            font-size: 12px;
            margin: auto;
            text-align: left;
          }
        }

        &__fallbackMessageSubtitle {
          font-family: $rxc-costa-Interstate-font;
          font-size: 10px;
          font-weight: 400;
          text-align: left;
          line-height: 12px;

          @include rxc-mobile-only {
            font-size: 12px;
            margin: auto;
            text-align: left;
          }
        }
      }

      .Loader__container {
        width: 34px;
        height: 34px;
        margin-left: 0;

        .Loader__iconNonGreen {
          width: 34px;
          height: 34px;
        }
      }
    }

    &__productDescriptionContainer {
      border-left: 1px solid $rxc-fontColor-costa;
      height: fit-content;
      position: relative;
      margin-left: 35%;
      width: 240px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      font-family: $rxc-costa-Interstate-font;
      margin-bottom: 0;
      margin-top: -10%;

      &__dot {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: $rxc-fontColor-costa;
        position: inherit;
        margin-left: -5px;
      }

      &__title {
        font-family: $rxc-costa-Interstate-font;
        font-size: 16px;
        font-weight: bold;
        color: $rxc-fontColor-costa;
        margin-left: 14.5px;
        margin-bottom: 16px;
        line-height: 24px;
        margin-top: -10px;
      }

      &__description {
        font-family: $rxc-costa-Interstate-font;
        font-size: $rxc-sun-default-12-fontSize;
        color: $rxc-fontColor-costa;
        margin-left: 14.5px;
        font-weight: 400;
        margin-bottom: 16px;
        line-height: 16px;
      }

      &__text {
        display: flex;
        flex-flow: row;
        margin-left: 14.5px;
        line-height: 25px;
        font-family: $rxc-costa-Interstate-font;
        font-size: $rxc-sun-default-14-fontSize;
        color: $rxc-fontColor-costa;
        white-space: nowrap;

        &__inner {
          font-family: $rxc-costa-Interstate-font;
          font-size: $rxc-sun-default-14-fontSize;
          color: $rxc-fontColor-costa;
          font-weight: bold;
          margin-left: 8px;
          line-height: 24px;
          white-space: break-spaces;
        }
      }
    }
  }

  &__topBrandContainer {
    position: fixed;
    left: 40px;
    top: 30px;
    &__defaultTopBrandImage {
      min-width: 172px;
      min-height: 21px;
    }
  }

  &__bottomBrandContainer {
    @include rxc-mobile-only {
      display: none;
    }

    display: flex;
    flex-direction: column;
    position: absolute;
    left: 15px;
    bottom: 15px;

    &__productBrand {
      @include rxc-mobile-only {
        display: none;
      }

      display: none;
      max-width: 120px;
    }

    &__productModelContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 4px;

      &__model {
        margin-bottom: 0px;
        font-family: $rxc-costa-Interstate-font;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #182446;
      }

      &__frameSize {
        font-family: $rxc-costa-Interstate-font;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #182446;
      }
    }
  }
}