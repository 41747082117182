@import "../../mixins/global";

.MainContent {
  &__container {
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-bottom: solid 1px #d3d3d3;
    justify-content: space-between;

    @include rxc-desktop-only {
      background-color: #f6f6f6;
    }

    @include rxc-mobile-only {
      flex-flow: column nowrap;
      background-color: #ffffff;
      overflow-y: hidden !important;
    }
  }
}