@import "../../mixins/global";

.PrescriptionUploadForm__PupillaryDistance__optionsContainer .margin {
  margin: 0 10px !important;
}

.PrescriptionUploadForm {
  &__subtitle {
    font-size: 14px;
    font-family: "sofia-pro";
    margin-bottom: 32px;
  }

  &__agreement {
    .CustomCheckbox {
      &__checkbox {
        -webkit-appearance: none;
        background-color: white;
        border: 1px solid rgb(118, 118, 118);
        min-width: 13px;
        height: 13px;
        border-radius: 3px;
        display: inline-block;
        position: relative;

        &.marginTop {
          margin-top: 4px;
        }

        &.checked {
          background: black;

          &:after {
            content: "\2714";
            font-size: 11px;
            position: absolute;
            top: -3.5px;
            left: 1px;
            color: white;
          }
        }

        &:hover {
          border: 1px solid rgb(90, 90, 90);
        }
      }
    }

    .AgreementCheckbox {
      &__container {
        display: flex;
        align-items: flex-start;
        margin-top: 24px;

        &.invisible {
          display: none;
        }
      }

      &__text {
        font-family: Interstate;
        margin-left: 10px;
      }

      &__tooltip {
        position: relative;
        height: 16px;
        width: 16px;
        min-width: 16px;
        margin-left: 8px;
        cursor: pointer;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z' stroke='%23000000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 16V12' stroke='%23000000' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 8H12.01' stroke='%23000000' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        position: relative;

        &:hover {
          cursor: pointer;

          .AgreementCheckbox__tooltip__body {
            display: block;
          }
        }

        @include rxc-mobile-only {
          display: inline-block;
          margin-top: 10px;
          margin-bottom: -3px;
        }

        &__body {
          display: none;
          position: absolute;
          //top: -160px;
          left: -180px;
          background: #f7f6f4;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
          -webkit-border-radius: 8px !important;
          border-radius: 8px;
          border: 1px solid #e5e5e5;
          // outline: 1px solid #e5e5e5;
          font-family: "sofia-pro";
          font-size: 14px;
          font-weight: 400;
          padding: 16px;
          line-height: 150%;

          @include rxc-desktop-only {
            width: 375px;
            //height: 150px;
          }

          @include rxc-mobile-only {
            left: -153px;
            //top: -192px;
            width: 90vw;
          }

          &::after {
            content: "";
            position: absolute;
            width: 29px;
            height: 18px;
            bottom: -16px;
            left: 46%;
            z-index: 100;
            background-image: url("data:image/svg+xml,%3csvg width='16' height='8' viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 8L0 0L16 0L8 8Z' fill='%23f7f6f4'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;

            @include rxc-mobile-only {
              left: 146px;
            }
          }
        }
      }
    }

    .SimpleTextError {
      &__message {
        font-family: "sofia-pro";
        color: #ff0000;
        margin-top: 10px;
        font-size: 12px;
      }
    }
  }

  &__submitContainer {
    display: flex;
    position: relative;
    bottom: 0;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 50px;
    position: absolute;
    bottom: 100px;
    right: 40px;

    @include rxc-mobile-only {
      width: 100%;
      bottom: -2%;
      right: 0;
    }
  }

  &__submitButton {
    height: 40px;
    width: fit-content;
    background-color: $rxc-fontColor-costa;
    color: #fff !important;
    text-transform: uppercase;
    font-family: $rxc-costa-Interstate-font;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    padding: 12px;
    align-self: center;
    border: solid 1px $rxc-fontColor-costa;
    letter-spacing: 0.5px;
    cursor: pointer;

    @include rxc-mobile-only {
      width: 100%;
      margin-bottom: 0px;
      bottom: unset;
      margin-top: 30px;
    }

    &:hover {
      padding: 12px 16px;
      height: 48px;
    }
  }

  &__button {
    width: fit-content;
    height: 40px;
    padding: 10px 35px 10px 35px;
    background-color: #fff;
    border: none;
    position: relative;
    z-index: 1;
    font-size: 14px;
    font-family: "Interstate";
    font-weight: bold;
    text-align: center;
    color: #182446;
    border: 1px solid #182446;
    text-transform: uppercase;
    cursor: pointer;
    letter-spacing: 0.5px;

    @include rxc-mobile-only {
      border: none;
      padding: 0;
      font-size: 12px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #007ac2;
      text-transform: lowercase;
      text-decoration: underline;

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }

  &__UploadBox {
    &__container {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: fit-content;
      padding: 24px;
      border: solid 1px #222222;
      min-height: 160px;

      &.error {
        border: solid 1px #ca3300;
      }

      &__spinnerContainer {
        display: flex;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 100%;
        background-color: red;
      }

      &__topContainer {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
      }

      &__bottomContainer {
        width: 100%;
        margin-top: 10px;
      }

      &__changeMethod {
        font: 12px "sofia-pro-bold", Verdana, Arial, sans-serif;
        text-decoration: underline;
        color: #557b92;
        cursor: pointer;
        letter-spacing: 0.16px;
        line-height: 16px;

        @include rxc-mobile-only {
          display: none;
        }
      }

      &__changeMethodMobile {
        display: none;

        @include rxc-mobile-only {
          display: unset;
          font: 12px "sofia-pro-bold", Verdana, Arial, sans-serif;
          text-decoration: underline;
          color: #557b92;
          cursor: pointer;
          letter-spacing: 0.16px;
          line-height: 16px;
          margin-top: 15px;
          align-self: flex-end;
        }
      }
    }
  }

  &__UploadResult {
    &__container {
      display: flex;
      align-items: center;

      svg {
        min-width: 16px;
      }
    }

    &__unsuccessfulIconContainer {
      margin-right: 10px;
      margin-top: 2px;
    }

    &__successText {
      text-transform: uppercase;
      font-family: "Interstate";
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #1e7e34;
      margin-left: 10px;
    }

    &__errorText {
      font-family: "Interstate";
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #ca3300;

      &.uppercase {
        text-transform: uppercase;
      }
    }
  }

  &__FilePreview {
    &__container {
      display: flex;

      &__filePreview {
        justify-content: center;
        align-items: center;
        display: flex;
        width: 84px;
        height: 90px;
        margin-right: 15px;
        cursor: pointer;

        & .Loader__container {
          margin-left: 0;
        }

        &.noPreview {
          cursor: unset !important;
        }

        img {
          max-width: 84px;
          max-height: 90px;
          margin: auto;
        }
      }

      &__fileInfo {
        display: flex;
        flex-flow: column;
        margin-top: 10px;

        &__nameAndSize {
          font-size: 14px;
          font-family: "Interstate";
          line-height: 24px;
          word-break: break-all;
        }

        &__uploadDifferent {
          margin-top: 8px;
          font-size: 12px;
          font-family: "Interstate";
          text-decoration: underline;
          color: #007ac2;
          cursor: pointer;
        }
      }
    }
  }

  &__UploadError {
    &__container {
      &__message {
        margin-bottom: 17px;
        font-size: 12px;
        font-family: "Interstate";

        @include rxc-mobile-only {
          width: 40%;
        }
      }
    }
  }

  &__CustomCheckbox {
    &__checkbox {
      -webkit-appearance: none;
      background-color: white;
      border: 1px solid rgb(118, 118, 118);
      min-width: 16px !important;
      height: 16px !important;
      border-radius: 2px !important;
      display: inline-block;
      position: relative;

      &.marginTop {
        margin-top: 4px;
      }

      &.checked {
        background: black;
        border: none !important;

        &:after {
          content: "" !important;
          position: absolute !important;
          width: 10px;
          height: 10px;
          top: 3px !important;
          left: 3px !important;
          background-size: contain;
          background-image: url(https://assets.lenscrafters.com/extra/image/LensCrafters/global/check.png);
          background-repeat: no-repeat;
        }
      }

      &:hover {
        border: 1px solid rgb(90, 90, 90);
      }
    }
  }

  &__Separator {
    display: block;
    width: 100%;
    height: 1px;
    margin-top: 5%;
    background-color: $rxc-grey-separator;
  }
}

.StepContainer__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: 48px;

  @include rxc-mobile-only {
    margin-right: 0;
  }
}