@import "../../mixins/global";

.ExitModal {
  &__container {
    display: block;
    padding: 0;
    border-radius: 8px;
    box-shadow: 0 5.9px 11.8px 0 rgba(96, 97, 112, 0.16),
      0 0 2.9px 0 rgba(40, 41, 61, 0.04);
    background-color: #ffffff;

    &__overlay {
      position: absolute;
      background-color: rgba(51, 51, 51, 0.6);
      top: 0;
      left: 0;
      z-index: 102;
      width: 100%;
      height: 100%;
    }
    &__popup {
      border-radius: 8px;
      box-shadow: 0 5.9px 11.8px 0 rgba(96, 97, 112, 0.16),
        0 0 2.9px 0 rgba(40, 41, 61, 0.04);
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 472px;
      height: auto;
      padding: 40px 24px;
      text-align: center;
      overflow: hidden;
      transform: translateX(-50%) translateY(-50%);
      z-index: 999999999999999;
      @include rxc-desktop-only {
        min-height: 270px;
        justify-content: space-evenly;
      }

      @include rxc-mobile-only {
        width: 85%;
        padding: 30px 22px 26px 22px;
      }
    }
    &__title {
      color: #182446;
      margin-bottom: 4px;
      text-transform: uppercase;
      font-weight: bold !important;
      font-size: 24px;
      font-family: Interstate;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: normal;
    }
    &__subtitle {
      text-align: center;
      color: #222222;
      font-family: Interstate;
      font-size: 16px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
    }
    &__buttonsContainer {
      margin-top: 20px;
      width: 100%;
      height: 32px;
      flex-grow: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      @include rxc-mobile-only {
        width: 100%;
      }

      &__buttonExit,
      &__buttonContinue {
        letter-spacing: 0.5px;
        font-weight: 600;
        font: 16px $rxc-costa-Interstate-font, Verdana, Arial, sans-serif;
        padding: 4px 16px;

        @include rxc-mobile-only {
          font: 12px $rxc-costa-Interstate-font, Verdana, Arial, sans-serif;
          margin: 10px;
        }
      }

      &__buttonExit {
        background-color: #182446 !important;
        border: none;
        border-radius: 0px;
        width: 100%;
        height: 40px;
        color: #fff !important;
        text-transform: uppercase;
        cursor: pointer;
        margin-right: 40px;

        @include rxc-mobile-only {
          font-weight: 500;
          height: 32px;
          max-width: 180px;
          padding: 0px 24px;
          border-radius: 0px;
        }
        &:hover {
          padding: 4px 20px;
          height: 48px;
          margin-right: 35px;
        }
      }
      &__buttonContinue {
        border: solid 1px #182446;
        background-color: #fff;
        color: #182446;
        border-radius: 0px;
        background-color: #fff;
        width: 100%;
        height: 40px;
        color: #182446;
        text-transform: uppercase;
        cursor: pointer;

        @include rxc-mobile-only {
          font-weight: 500;
          height: 32px;
          max-width: 180px;
          padding: 0px 24px;
          border-radius: 0px;
        }
        &:hover {
          padding: 4px 20px;
          height: 48px;
          margin-left: -4px;
        }
      }
    }

    &__save {
      font-size: 16px;
      font-weight: 600;
      color: #182446;
      text-decoration: underline;
      cursor: pointer;
      margin-top: 24px;
      font: 16px $rxc-costa-Interstate-font, Verdana, Arial, sans-serif;
    }
  }
}
