@import "../../../mixins/global.scss";
.AddOns {
  &__includedContainer {
    width: fit-content;
    height: fit-content;
    margin-bottom: 30px;

    &__checkBox {
      display: none !important;
    }

    &__accordion {
      &__open {
        display: block;

        & ul {
          margin: 12px 0;

          & li {
            font-family: $rxc-costa-Interstate-font;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #1f1f24;
            padding-bottom: 0.3rem;

            &::marker {
              font-size: 0.6rem;
            }
          }
        }
      }

      &__closed {
        display: none;
      }

      &Button {
        font-family: $rxc-costa-Interstate-font;
        font-size: 16px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: $rxc-blue-costa;
        text-decoration: underline;
        cursor: pointer;
      }

      &Button.closed::after {
        position: relative;
        top: 3px;
        margin-left: 4px;
        content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.86195 5.52876C2.6016 5.78911 2.6016 6.21122 2.86195 6.47157L7.52862 11.1382C7.78896 11.3986 8.21108 11.3986 8.47142 11.1382L13.1381 6.47157C13.3984 6.21122 13.3984 5.78911 13.1381 5.52876C12.8777 5.26841 12.4556 5.26841 12.1953 5.52876L8.00002 9.72402L3.80476 5.52876C3.54441 5.26841 3.1223 5.26841 2.86195 5.52876Z' fill='%23557B92'/%3E%3C/svg%3E%0A");
      }
      &Button.open::after {
        position: relative;
        top: 3px;
        margin-left: 4px;
        content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.138 10.4712C13.3983 10.2109 13.3983 9.78878 13.138 9.52843L8.47132 4.86177C8.21097 4.60142 7.78886 4.60142 7.52851 4.86177L2.86185 9.52843C2.6015 9.78878 2.6015 10.2109 2.86185 10.4712C3.1222 10.7316 3.54431 10.7316 3.80466 10.4712L7.99992 6.27598L12.1952 10.4712C12.4555 10.7316 12.8776 10.7316 13.138 10.4712Z' fill='%23557B92'/%3E%3C/svg%3E%0A");
      }
    }
  }
}
