@import "../../mixins/global";

.LearnMoreModal__container .carousel-status {
  display: none;
}

.LearnMoreModal__container .control-dots {
  display: flex;
  align-items: center;
  justify-content: center;
}

.LearnMoreModal__container .card-gone {
  display: none;
}

.LearnMoreModal__container video {
  border-radius: 8px;
}

.LearnMoreModal__container .dot {
  width: 32px !important;
  height: 1px !important;
  background: #182446 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  margin: 0 2px !important;
}

.LearnMoreModal__container .dot.selected {
  width: 32px !important;
  height: 1px !important;
  background-color: #007ac2 !important;
  box-shadow: none !important;
}

.LearnMoreModal__container .carousel.carousel-slider {
  @include rxc-mobile-only {
    width: 100% !important;
  }
}

.LearnMoreModal__container .slide {
  @include rxc-mobile-only {
    min-width: 80% !important;
  }
}

.LearnMoreModal__container .slider {
  background-color: transparent !important;
}

.LearnMoreModal__container__carouselMarginfirst .slider-wrapper {
  @media only screen and (min-width: 1200px) {
    transform: translateX(15%) !important;
    transition: 0.3s !important;
  }
}

.LearnMoreModal__container__carouselMarginlast .slider-wrapper {
  @media only screen and (min-width: 1200px) {
    transform: translateX(-15%) !important;
    transition: 0.3s !important;
  }
}

.LearnMoreModal__container .tappable {
  cursor: url(https://assets.lenscrafters.com/extra/image/rxc/images/tap_oval.png)
      57 57,
    auto;
}

.LearnMoreModal__container .slide.selected {
  @include rxc-mobile-only {
    min-width: 80% !important;
  }
}

.LearnMoreModal__container .card-invisible {
  display: none;
}

.CardInfoPanel {
  &__container {
    position: absolute;
    height: auto !important;
    border-radius: 8px;
    box-shadow: 0 0 0 0 rgba(8, 27, 61, 0.4);
    background-image: radial-gradient(
      circle at 0 0,
      rgba(255, 255, 255, 0.29),
      rgba(255, 255, 255, 0.29)
    );

    @include rxc-desktop-only {
      top: 50%;
      transform: translateY(-60%);
      width: 25% !important;
      right: 80px;
      padding: 52px 35px 52px 29px;
    }

    @include rxc-mobile-only {
      bottom: 50px;
      left: 50px;
      width: calc(100% - 50px) !important;
      padding: 18px 15px 35px 15px;
    }
  }

  &__title {
    color: white;
    font-family: $rxc-costa-TungstenSemibold-font !important;
    font-size: 32px !important;
    -webkit-font-smoothing: antialiased;
    text-align: left;
    margin-bottom: 8px;
    line-height: 0.8;
    letter-spacing: 0.14px;
    text-transform: uppercase;

    @include rxc-mobile-only {
      font-size: 20px !important;
      margin-bottom: 16px;
    }
  }

  &__body {
    font-family: $rxc-costa-Interstate-font;
    font-size: 14px;
    font-weight: normal;
    color: white;
    text-align: left;
    line-height: 1.71;
    letter-spacing: 0.5px;

    & div {
      margin-bottom: 15px;
    }

    & ul {
      padding-inline-start: 22px;
      @include rxc-mobile-only {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
        padding: 0 0 0 10px;
      }
    }

    & li {
      font-family: $rxc-costa-Interstate-font;
      font-size: 12px;
      font-weight: normal;
      color: white;
      text-align: left;
      line-height: 2.5;
      letter-spacing: 0.5px;

      @include rxc-mobile-only {
        font-size: 10px;
        letter-spacing: 0;
      }
    }
  }
}

.LearnMoreModal {
  &__leftCarouselArrow {
    @include rxc-mobile-only {
      display: none;
    }

    background-image: url("data:image/svg+xml,%3Csvg width='11' height='20' viewBox='0 0 11 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='24' width='11' height='20' rx='12' fill='%23182446'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.6465 23.8535L0.792969 12L12.6465 0.146484L13.3535 0.853484L2.20697 12L13.3535 23.1465L12.6465 23.8535Z' fill='%23182446'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    margin-right: 15px;
    margin-left: 0;
    border: none;
    padding: 0;
    cursor: pointer;
    background-color: transparent;

    &.invisible {
      visibility: hidden;
    }
  }

  &__rightCarouselArrow {
    @include rxc-mobile-only {
      display: none;
    }

    background-image: url("data:image/svg+xml,%3Csvg width='11' height='20' viewBox='0 0 11 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='24' width='11' height='20' rx='12' fill='%23182446'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.711237 19.8779L0.12207 19.2887L9.41082 9.99999L0.12207 0.711237L0.711237 0.12207L10.5892 9.99999L0.711237 19.8779Z' fill='%23182446'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    margin-left: 15px;
    border: none;
    padding: 0;
    cursor: pointer;
    background-color: transparent;

    &.invisible {
      visibility: hidden;
    }
  }

  &__container {
    // display: none;
    position: fixed !important;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(255, 255, 255, 0.5);
    z-index: 9;
    padding-top: 100px;
    padding-bottom: 100px;
    backdrop-filter: blur(0.3rem);

    @include rxc-mobile-only {
      top: -50px;
    }

    /*         &__carouselMarginfirst .carousel-slider .slider-wrapper .slider  {   
            @media only screen and (min-width: 1200PX) {
                transform: translateX(15%);
                transition: 0.3s;
            }         
            
         & .carousel .control-dots {
            display: flex;
            align-items: center;
            justify-content: center;

            @media only screen and (min-width: 1200PX) {
                left: 35%;
                transform: translateX(-50%);
            }
            
        } 
            


        } */
    /*         &__carouselMarginlast{
            @media only screen and (min-width: 1200PX) {                
                transform: translateX(-15%);            
                transition: 0.3s;
            }
             & .carousel .control-dots {
                @media only screen and (min-width: 1200PX) {                    
                    left: 65%;
                    transform: translateX(-50%);
                }
            } 

            
        } */
    &__carouselMiddle {
      @media only screen and (min-width: 1200px) {
        transition: 0.3s;
      }

      /* & .carousel .control-dots {
                @media only screen and (min-width: 1200PX) {                    
                left: 50%;
                transform: translateX(-50%);
                }
            }  */
    }

    & li.slide {
      padding-left: 50px;
      padding-right: 50px;
      padding-bottom: 50px;
      overflow: hidden;
      height: fit-content;

      @include rxc-mobile-only {
        padding-right: 0;
        height: clamp(28.4rem, -0rem + 80vh, 51.2rem);
      }

      /*             @media only screen and (max-device-width: 320px) {
                height: 76vh !important;
            }
 */
      & {
        border-radius: 8px;
      }

      & > div {
        overflow: hidden;
        width: 100%;
        height: 100%;
        border-radius: 8px;

        &::after :first-child {
          content: " ";
          box-shadow: 0 23.6px 47.1px 0 rgb(96 97 112 / 16%),
            0 5.9px 11.8px 0 rgb(40 41 61 / 4%);
          position: absolute;
          top: 0;
          left: 3.7%;
          right: 3.7%;
          bottom: 7%;
          border-radius: 8px;
        }
      }

      & video {
        @include rxc-desktop-only {
          width: 100%;
          height: 100%;
        }
        @include rxc-mobile-only {
          height: 100%;
        }
      }
    }
  }

  &__blur {
    display: none;
    position: fixed !important;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(255, 255, 255, 0.5);
    z-index: 8;
    backdrop-filter: blur(10px);
  }

  &__dotnav {
    bottom: 45px !important;
    z-index: 9;
  }
}
