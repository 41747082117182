@import "../../mixins/global";

.FullPage_root {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100001;
  display: flex;
  flex-flow: column nowrap;
  width: inherit;
  height: 100%;
  background-color: #f6f6f6;
  right: 0;
  color: $rxc-fontColor-costa !important;

  &:focus {
    outline: none !important;
    border: none !important;
  }
  *:focus {
    outline: none; //as requested in ticket RXPANEL-1351 CDM - Focus must not be visible
  }

  & * {
    box-sizing: border-box;
    //RXPANEL-1466
    /* ::-webkit-scrollbar {
            width: 0; 
            background: transparent;
        } */
    //color: $rxc-fontColor-costa;

    /*         @include rxc-desktop-only {
            font: 16px ;
            font-family: $rxc-glasses-DMSans-font;
        }
        
      @include rxc-mobile-only {
            font-family: $rxc-costa-Interstate-font;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: 0.16px;
            text-align: left;
           ; 
        } */
  }
}

:global #rxcApp {
  /** {
        box-sizing: border-box;
        font: 15px "sofia-pro-light", Verdana, Arial, sans-serif;
    }*/
  .cursor-hand {
    cursor: pointer;
  }

  .arrow {
    border: solid $rxc-black-color-1;
    border-width: 0.1px;
    border-width: 0 1px 1px 0;
  }

  .arrow-right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .lc-control {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 15px;
    cursor: pointer;
    font-weight: 300;

    &:hover {
      input {
        &:not([disabled]) {
          & ~ .lc-control__indicator {
            border-color: $rxc-grey-color-7;
          }
        }
      }
    }

    input {
      position: absolute;
      z-index: -1;
      opacity: 0;

      &:focus {
        & ~ .lc-control__indicator {
          border-color: $rxc-grey-color-7;
        }
      }

      &:checked {
        & ~ .lc-control__indicator {
          background: $rxc-white-color-1;

          &:after {
            display: block;
          }
        }
      }

      &:disabled {
        & ~ .lc-control__indicator {
          background: #e6e6e6;
          opacity: 0.6;
          pointer-events: none;
        }
      }
    }

    .lc-control__indicator {
      position: absolute;
      top: 0px;
      left: 0;
      height: 15px;
      width: 15px;
      background: $rxc-white-color-1;
      border: 1px solid $rxc-black-color-1;

      &:after {
        content: "";
        position: absolute;
        display: none;
      }
    }

    &.lc-control--checkbox {
      .lc-control__indicator {
        &:after {
          left: 4px;
          top: 0px;
          width: 4px;
          height: 10px;
          border: solid $rxc-fontColor-costa;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }

        input {
          &:disabled {
            & ~ .lc-control__indicator {
              &:after {
                border-color: #7b7b7b;
              }
            }
          }
        }
      }
    }

    &.lc-control--radio {
      .lc-control__indicator {
        border-radius: 50%;

        &:after {
          left: 4px;
          top: 4px;
          height: 6px;
          width: 6px;
          border-radius: 50%;
          background: $rxc-fontColor-costa;
        }
      }
    }
  }

  .SwitchControl_container {
    //margin-top: 20px;
    @include rxc-mobile-only {
      min-height: 20px;
    }

    .SwitchControl_label {
      position: relative;
      display: inline-block;
      width: 44px;
      height: 24px;
      margin-right: 10px;

      @include rxc-mobile-only {
        float: left;
      }

      input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .slider {
          background-color: #007ac2;
        }

        &:checked + .slider::before {
          //-webkit-transform: translateX(18px);
          //-ms-transform: translateX(18px);
          //transform: translateX(18px);
          -webkit-transform: translateX(22px);
          -ms-transform: translateX(22px);
          transform: translateX(22px);
        }

        &:focus + .slider {
          //box-shadow: 0px 0px 0px 3px $rxc-blue-costa;
          transition: 0s;
        }
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #eceff0; //$rxc-white-color-1;
        //border: solid 1px $rxc-black-color-1;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 34px;
        height: 93% !important;

        @include rxc-mobile-only {
          width: 42px !important;
        }

        &::before {
          position: absolute;
          content: "";
          height: 18px;
          width: 18px;
          left: 0px; //4px;
          bottom: 2px;
          background-color: white;
          -webkit-transition: 0.4s;
          transition: 0.4s;
          border-radius: 50%;
        }
      }
    }

    .two-pd-label {
      position: absolute;

      @include rxc-mobile-only {
        position: relative;
        float: left;
      }
    }
  }

  .border-button {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    font-family: Interstate;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
  }
}

.RXC {
  &__is-locked {
    @include rxc-mobile-only {
      height: calc(100vh - 1px) !important;
      box-sizing: border-box !important;
      overflow: hidden !important;
    }
  }
}
