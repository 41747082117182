@import "../../mixins/global";

.StepTitle {
  &__btn {
    @include rxc-mobile-only {
      width: 90%;
      margin-left: 0px;
      position: absolute;
      background-color: $rxc-blue-costa;
      padding: 8px 20px 8px 20px;
      right: auto;
      font-size: $rxc-sun-default-16-fontSize;
      font-family: $rxc-costa-Interstate-font;
      font-weight: 700;
      margin-bottom: 320px;
    }

    width: fit-content;
    height: 40px;
    margin: 0;
    padding: 10px 35px 10px 35px;
    border-radius: 100px;
    background-color: $rxc-blue-costa;
    border: none;
    position: absolute;
    z-index: 1;
    right: 72px;
    margin-bottom: 23px;
    font-family: $rxc-costa-Interstate-font;
    font-size: $rxc-sun-default-14-fontSize;
    font-weight: 500;
    text-align: center;
    color: $rxc-white-color-1;
    text-transform: uppercase;

    &_container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 92px;
      left: 0;
    }
  }

  &__filtersContainer {
    display: flex;
    margin-top: 8px;
    margin-bottom: 18px;
    overflow-x: auto;

    @include rxc-mobile-only {
      margin-bottom: 18px;
      margin-top: 18px;
    }
  }

  &__filterPill {
    background-color: $rxc-white-color-1;
    cursor: pointer;
    font-family: $rxc-costa-Interstate-font;
    text-transform: capitalize;
    margin: 0 16px 0 0;
    padding: 8px 16px;
    border-radius: 100px;
    border: solid 1px $rxc-grey-separator;
    font-size: $rxc-sun-default-12-fontSize;
    white-space: nowrap;

    &.selected {
      cursor: pointer;
      text-transform: capitalize;
      padding: 8px 16px;
      margin: 0 16px 0 0;
      font-family: $rxc-costa-Interstate-font;
      color: $rxc-white-color-1;
      background-color: $rxc-blue-costa;
      border-radius: 100px;
      border: solid 1px $rxc-blue-costa;
      font-size: $rxc-sun-default-12-fontSize;
    }
  }

  &__slider {
    &__container {
      width: fit-content;
      margin-bottom: 16px;
      border-radius: 100px;
      font-family: $rxc-costa-Interstate-font;
      border: solid 1px $rxc-blue-costa;

      @include rxc-mobile-only {
        margin-top: 16px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin: 16px auto 0px auto;
      }

      &__btn {
        width: fit-content;
        background-color: $rxc-white-color-1;
        border-radius: 100px;
        border: 1px solid $rxc-white-color-1;
        font-size: $rxc-sun-default-14-fontSize;
        font-family: $rxc-costa-Interstate-font;
        color: $rxc-blue-costa;
        padding: 8px 16px 8px 16px;
        transition: 0.3s;

        @include rxc-mobile-only {
          box-sizing: border-box;
          height: 32px;
          font-size: $rxc-sun-default-12-fontSize;
        }

        &.selected {
          color: $rxc-white-color-1;
          background-color: $rxc-blue-costa;
          border-radius: 100px;
          font-family: $rxc-costa-Interstate-font;
          font-size: $rxc-sun-default-14-fontSize;
          border: 1px solid $rxc-blue-costa;
          padding: 8px 16px 8px 16px;

          @include rxc-mobile-only {
            width: fit-content;
            font-size: $rxc-sun-default-12-fontSize;
          }
        }
      }
    }
  }

  &__container {
    flex-flow: column nowrap;

    @include rxc-desktop-only {
      padding: 8px 0 20px;
    }

    @include rxc-mobile-only {
      padding: 16px 0px 16px 0;

      //filterpills separators and buttons hiddden as requested per RXPANEL-1352
      .filterPills__fadecontainer__filtersContainer__fade__left {
        display: none;
      }

      .filterPills__fadecontainer__filtersContainer__separatorvertical__left {
        display: none;
      }

      .filterPills__fadecontainer__filtersContainer__scrollButton__left {
        display: none;
      }

      .filterPills__fadecontainer__filtersContainer__separatorvertical__right {
        display: none;
      }

      .filterPills__fadecontainer__filtersContainer__scrollButton__right {
        display: none;
      }
    }

    .optionsSlider__slider__container {
      background-color: #ECEFF0;
      border: 1px solid #ECEFF0;

      &__btn {
        background-color: #ECEFF0;
        border: 1px solid #ECEFF0;
        color: #182446;

        &.clicked {
          color: #ECEFF0;
          background-color: #182446;
          border: 3px solid #182446;
        }
        border: 3px solid #eceff0 !important;

        &.clicked {
          border: 3px solid #182446 !important;
        }

      }
    }

    &__title {
      font-family: "Interstate";
      font-size: 32px;
      line-height: 1.19;
      letter-spacing: -1.6px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      color: #182446;
      text-align: start;
      text-transform: uppercase;

      @include rxc-mobile-only {
        display: none;
      }
    }

    &__titleMobile {
      display: none;

      @include rxc-mobile-only {
        display: block;
        font-family: Interstate;
        font-size: 20px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: #182446;
        text-transform: uppercase;

        &::first-letter {
          text-transform: capitalize;
        }
      }
    }

    &__subtitle {
      box-sizing: border-box;
      font-family: $rxc-costa-Interstate-font;
      font-size: $rxc-sun-default-14-fontSize;
      text-align: left;
      color: $rxc-fontColor-costa;
      margin-top: 8px;
      word-wrap: break-word;
    }

    &__pillsSubtitle {
      box-sizing: border-box;
      font-family: $rxc-arnette--font-regular;
      font-size: 12px;
      text-align: left;
      color: #000000;
      margin-top: 16px;
      word-wrap: break-word;
    }
  }
}

@include rxc-mobile-only {
  .review .StepTitle__container__subtitle {
    margin-bottom: 4px;
  }
}