@import "../../mixins/global";

.ReadOnlyTable {
  &__container {
    display: flex;
    flex-direction: column;
    transition: transform 0.07s ease-in-out;

    &__top {
      display: flex;

      @include rxc-mobile-only {
        display: flex;
        flex-direction: column;
        flex: 1;
      }

      &__cell {
        height: 38px;
        width: 128px;
        text-align: center;
        border: 1px solid #b0b0b0;
        margin-left: -1px;
        margin-bottom: -1px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f6f6f6;
        font-family: $rxc-costa-Interstate-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;

        @include rxc-mobile-only {
          width: unset;
          font-size: 15px;
        }
      }
    }

    &__right {
      @include rxc-mobile-only {
        display: flex;
        flex: 2;

        & div {
          display: flex;
          flex-direction: column;
        }
      }

      &__cell,
      &__cellValue {
        height: 38px;
        width: 128px;
        text-align: center;
        border: 1px solid #b0b0b0;
        margin-left: -1px;
        margin-bottom: -1px;
        display: flex;
        align-items: center;
        justify-content: center;
        // @include rxc-mobile-only {
        //   width: 115px;
        // }
      }

      &__cell {
        background-color: #f6f6f6;
        font-family: $rxc-costa-Interstate-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }

      &__cellValue {
        font-family: $rxc-costa-Interstate-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }

    &__pd {
      &__container {
        display: flex;
        margin: 24px 0px;

        @include rxc-mobile-only {
          margin-top: 16px;
        }

        & div {
          width: 126px;
          height: 38px;
          display: flex;
          align-items: center;
          justify-content: center;

          @include rxc-mobile-only {
            width: unset;
          }
        }

        &__label {
          color: var(--base-text-dark, var(--color-brand-text-dark, #000));
          text-align: center;
          font-family: "sofia-pro";
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          border: 1px solid #b0b0b0;
          background-color: #f6f6f6;
          margin-left: -1px;

          @include rxc-mobile-only {
            flex: 1;
          }
        }

        &__value {
          color: var(--base-gray-dark, var(--color-gray-gray-dark, #767676));
          text-align: center;
          font-family: "sofia-pro";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          border: 1px solid #b0b0b0;
          margin-left: -1px;
        }
      }

      &__containerDouble {
        & div {
          min-width: 128px;
          min-height: 38px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        display: flex;
        flex-direction: column;
        margin: 24px 0px;

        &__label {
          color: var(--base-text-dark, var(--color-brand-text-dark, #000));
          text-align: center;
          font-family: "sofia-pro";
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          border: 1px solid #b0b0b0;
          background-color: #f6f6f6;
          margin-left: -1px;
          margin-bottom: -1px;
        }

        &__value {
          color: var(--base-text-dark, var(--color-brand-text-dark, #000));
          text-align: center;
          font-family: "sofia-pro";
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          border: 1px solid #b0b0b0;
          background-color: #f6f6f6;
          margin-left: -1px;
          margin-bottom: -1px;
        }
      }
    }
  }
}