@import "../../mixins/global.scss";

.PrescriptionFromMyAccountCard {
  align-items: flex-end;
  padding: 16px;
  // gap: 16px;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  position: relative;
  color: #222222;
  cursor: pointer;
  margin-top: 24px;
  font-family: "sofia-pro";
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &:hover {
    background-color: white;
    box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.12);
    outline: none;
    &::after {
      content: " ";
      display: block;
      position: absolute;
      top: -2px;
      left: -2px;
      width: 100%;
      height: 100%;
      border: 2px solid #326fa8;
      border-radius: 8px;
      pointer-events: none;
      box-sizing: content-box;
    }
  }
  @include rxc-mobile-only {
    flex-direction: column;
    align-items: flex-start;
  }
  &__name {
    margin: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    display: inline-block;
    font-family: "sofia-pro";
    &::first-letter {
      text-transform: uppercase;
      display: block;
    }
  }
  &__lastUpdate {
    margin: 0px;
    font-weight: 400;
    font-size: 14px;
    color: #767676;
    line-height: 21px;
    margin-bottom: 16px;
    display: flex;
    gap: 8px;
    // display: flex;
    // width: 100%;
    // flex-direction: row;
    & div {
      display: flex;
      gap: 8px;
    }
    & p {
      margin: 0px;
      font: $rxc-default-sofiaLight-font;
    }
    &::first-letter {
      text-transform: uppercase;
    }

    @include rxc-mobile-only {
      position: static;
      // flex-direction: column;
      // align-items: flex-start;
      // display: block;
      margin-top: 8px;
    }
  }
  &__expiredDate {
    margin: 0px;
    font-weight: 400;
    font-size: 14px;
    color: #767676;
    line-height: 21px;
    position: absolute;
    top: 16px;
    right: 16px;
    &::first-letter {
      text-transform: uppercase;
    }

    @include rxc-mobile-only {
      position: static;
      flex-direction: column;
      align-items: flex-start;
      display: block;
      margin-top: 8px;
    }
  }
  &__showPrescription {
    text-decoration: underline;
    font-weight: 700;
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 0;
    cursor: pointer;
    font-family: "sofia-pro";

    &::first-letter {
      text-transform: uppercase;
    }
  }
  &__olderThanYearBanner {
    background: #e7eef0;
    font-size: 12px;
    line-height: 16px;
    color: #244c5a;
    margin-top: 16px;
    margin-bottom: 0;
    padding: 8px;
    width: 100%;
  }
  &__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 24px;
    gap: 8px;
    background: #000000;
    border-radius: 4px;
    color: white;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;

    @include rxc-mobile-only {
      width: 100%;
    }
    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.Banner {
  &__container {
    position: absolute;
    z-index: 100000000;
    height: 24px;
    top: -12px;
    right: 10px;
    padding: 0px 16px;
    border-radius: 100px;
    background-color: #0354a6;
    color: #ffffff;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    font-family: "sofia-pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;

    & p {
      &::first-letter {
        text-transform: capitalize;
      }
    }

    & svg {
      height: 8px;
      width: 8px;
      margin-bottom: 1px;
    }
  }
}
