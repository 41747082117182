@import "../../mixins/global";

@keyframes show-title-animation {
  from {
    width: 0;
    height: 0;
  }
  50% {
    width: auto;
    height: 83px;
  }
  to {
    width: auto;
    height: 83px;
  }
}
@keyframes slide-top-animation {
  from {
    width: auto;
    height: 83px;
  }
  50% {
    width: auto;
    height: 83px;
  }
  75% {
    width: auto;
    height: 83px;
  }
  to {
    width: 0;
    height: 0;
  }
}

.YourSelection {
  @include rxc-mobile-only {
    display: none;
  }
  &__overlay {
    background-color: $rxc-grey2-sun-default;
    opacity: 50%;
    width: 50%;
    height: 100%;
    position: absolute;
    z-index: 99;
  }
  &__container {
    position: fixed;
    top: 24px;
    right: 52%;
    border-radius: 8px;
    cursor: pointer;
    color: $rxc-fontColor-costa;
    background-color: $rxc-white-color-1;
    z-index: 100;

    &.isOpen {
      cursor: initial;
      width: 391px;
    }
    &__title_wrapper {
      display: flex;
      align-items: center;
      border-radius: 8px;
      padding: 8px 16px;
      background-color: $rxc-white-color-1;
      &.isOpen {
        margin-bottom: 27px;
        width: 100%;
      }
      &.isClose {
        position: relative;
        box-shadow: 0px 0px 2.94545px rgba(40, 41, 61, 0.04),
          0px 5.89091px 11.7818px rgba(96, 97, 112, 0.16);
        align-items: center;
      }
      &__listIcon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
        background: image-set(
          url(#{$globalImageUrl}list.png) 1x,
          url(#{$globalImageUrl}list@2x.png) 2x,
          url(#{$globalImageUrl}list@3x.png) 3x
        );
        background: -webkit-image-set(
          url(#{$globalImageUrl}list.png) 1x,
          url(#{$globalImageUrl}list@2x.png) 2x,
          url(#{$globalImageUrl}list@3x.png) 3x
        );
        background-repeat: no-repeat;
        background-size: 100%;
        align-self: center;
      }
      &__title {
        text-transform: uppercase;
        font-family: $rxc-costa-Interstate-font;
        font-size: $rxc-sun-default-14-fontSize;
        font-weight: 700;
        letter-spacing: 0.5px;
        line-height: 24px;
      }
      &__close {
        display: block;
        margin-left: auto;
        content: "";
        background: image-set(
          url(#{$globalImageUrl}xclose.png) 1x,
          url(#{$globalImageUrl}xclose@2x.png) 2x,
          url(#{$globalImageUrl}xclose@3x.png) 3x
        );
        background: -webkit-image-set(
          url(#{$globalImageUrl}xclose.png) 1x,
          url(#{$globalImageUrl}xclose@2x.png) 2x,
          url(#{$globalImageUrl}xclose@3x.png) 3x
        );
        background-repeat: no-repeat;
        color: transparent;
        width: 24px;
        height: 24px;
        background-size: 100%;
        align-self: center;
        cursor: pointer;
      }
    }
    &__content_wrapper {
      width: 100%;
      padding: 0 16px 12px 16px;
      &__title {
        display: flex;
        margin-bottom: 5px;
        &__step {
          margin-right: 4px;
          font-family: $rxc-costa-Interstate-font;
          font-size: $rxc-sun-default-12-fontSize;
          line-height: 16px;
          letter-spacing: 0.16px;
        }
        &__edit {
          color: $rxc-blue-costa;
          font-family: $rxc-costa-Interstate-font;
          font-size: $rxc-sun-default-12-fontSize;
          font-weight: 700;
          line-height: 16px;
          letter-spacing: 0.16px;
          text-transform: capitalize;
          cursor: pointer;
        }
        &__price_container {
          display: flex;
          margin-left: auto;
          font-family: $rxc-costa-Interstate-font !important;
          font-size: $rxc-sun-default-16-fontSize !important;
          line-height: 18px;
          .Price__container__inner__strikePrice {
            font-family: $rxc-costa-Interstate-font !important;
            font-size: $rxc-sun-default-12-fontSize !important;
            line-height: 18px;
            text-decoration: line-through;
            margin-left: 6px;
          }
          .Price__container__inner__price {
            font-family: $rxc-costa-Interstate-font !important;
            font-size: $rxc-sun-default-16-fontSize !important;
            line-height: 18px;
          }
        }
      }
      &__selection {
        font-family: $rxc-costa-Interstate-font;
        font-size: $rxc-sun-default-12-fontSize;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0.16px;
      }
      &__separator {
        height: 1px;
        background-color: $rxc-grey-separator;
        width: 100%;
        margin: 12px 0 1px 0;
        &:first-child {
          display: none;
        }
      }
    }
    &__current_title {
      display: flex;
      align-items: flex-end;
      width: 0;
      height: 0;
      overflow: hidden;
      padding: 12px 16px;
      background-color: $rxc-white-color-1;
      border-radius: 8px;
      color: $rxc-fontColor-costa;
      margin-top: -24px;
      z-index: 99;
      &.animated {
        animation: show-title-animation 1s 0.5s, slide-top-animation 2s 1.5s;
      }
    }
  }
}
.hideIncluded {
  display: none;
}
.includedInsurance {
  color: #247534 !important;
  font-family: $rxc-costa-Interstate-font;
  font-weight: bold;
}
