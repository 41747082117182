@import "../../mixins/global";

.MyAccountPrescriptionDetailModal {
  text-align: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  font-family: $rxc-default-sofia-font;

  &__Modal {
    width: 50vw;
    max-width: 640px;
    margin: 0 auto;
    padding: 26px;
    background: white;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 4px;

    @include rxc-mobile-only {
      width: calc(100vw - 40px);
      max-height: calc(100vh - 160px);
    }

    h2 {
      text-align: left;
      margin: 0;
    }

    p {
      svg {
        position: relative;
        top: 2px;
        margin-right: 8px;
      }

      font-weight: 700;
      font-size: 16px;
      margin-top: 8px;
      color: #557b92;
      text-decoration: underline;
      text-align: left;
    }

    button {
      background: #000000;
      color: white;
      border-radius: 4px;
      padding: 9px 24px;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
    }

    &__closeIcon {
      width: fit-content;
      display: block;
      content: "";
      background: image-set(url(#{$globalImageUrl}xclose.png) 1x,
        url(#{$globalImageUrl}xclose@2x.png) 2x,
        url(#{$globalImageUrl}xclose@3x.png) 3x);
      background: -webkit-image-set(url(#{$globalImageUrl}xclose.png) 1x,
        url(#{$globalImageUrl}xclose@2x.png) 2x,
        url(#{$globalImageUrl}xclose@3x.png) 3x);
      background-repeat: no-repeat;
      color: transparent;
      min-width: 24px;
      height: 24px;
      background-size: 100%;
      align-self: center;
      cursor: pointer;
      position: absolute;
      top: 24px;
      right: 24px;
    }

    table,
    th,
    td {
      border: 1px solid;
      border-collapse: collapse;
    }
  }

  &__Overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: black;
    opacity: 0.5;
  }

  .ManualTable {
    margin-top: 24px;


    @include rxc-mobile-only {
      font-size: min(max(9px, calc(0.5625rem + ((1vw - 3.3px) * 15.5556))),
          16px);
      min-height: 0vw;
      padding: 0;
      max-height: 300px;
      overflow-y: scroll;
    }

    .marginTop {
      margin-top: 16px;
      word-break: break-word;
    }

    table {
      width: 100%;
      border-collapse: collapse;

      &.desktop_table {
        @include rxc-small-mobile-only {
          display: none;
        }
      }

      &.mobile_table {
        @include rxc-desktop-only {
          display: none;
        }

        @include rxc-mobile-only {
          display: none;
        }

        @include rxc-small-mobile-only {
          display: inline-table;
        }
      }
    }

    th,
    td {
      border: 1px solid #d8d8d8;
      padding: 10px;
      max-width: 50px;
    }
  }
}