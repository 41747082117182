@import "../../mixins/global";
.Price {
  &__container {
    display: flex;
    text-align: left;
    align-items: baseline;

    &__inner {
      font: 12px $rxc-costa-Interstate-font;
      //margin-bottom: 4px;
      &__strikePrice {
        margin-right: 6px;
        margin-left: 2px;
        color: $rxc-fontColor-costa;
        font: 12px $rxc-costa-Interstate-font;
        text-decoration: line-through;
      }
      &__price {
        font: 16px $rxc-costa-Interstate-font;
        color: $rxc-fontColor-costa;
      }
      &__green {
        font-size: 14px;
        color: $rxc-green-costa !important;
      }
    }
  }
}
