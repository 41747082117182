@import "../../mixins/global";

.CalifornianInput__containerEl .FullPrescriptionForm__innerContainer__applyButton {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  z-index: 100000;
  margin-top: 20px;
  height: 40px;
  width: fit-content;
  background-color: #007ac2;
  color: #fff !important;
  text-transform: uppercase;
  font-family: Interstate;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  padding: 12px;
  align-self: center;
  border: none;
  cursor: pointer;
  position: relative;
  bottom: unset;
  right: unset;

  @include rxc-mobile-only {
    width: 100% !important;
  }

  &__california {
    display: none;
  }
}

.FullPrescriptionForm {
  &__separator {
    margin: 24px 0;
    width: 100%;
    height: 1px;
    background-color: #dde5ed;

    @include rxc-desktop-only {
      margin: 20px 0;
    }

    @include rxc-mobile-only {
      margin: 20px 0;
    }
  }

  &__subtitle {
    font-family: "Interstate";
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #182446;
    margin-bottom: 15px;

    @include rxc-mobile-only {
      font-size: 12px;
    }
  }

  &__readInfo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    font-family: "Interstate";
    font-size: 14px;
    text-transform: uppercase;
    color: #222;
    margin-top: 24px;

    &::before {
      content: "";
      height: 16px;
      width: 16px;
      min-width: 16px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'%3E%3Cg clip-path='url(%23clip0_1262_7843)'%3E%3Cpath d='M8 0.5C3.58867 0.5 0 4.08867 0 8.5C0 12.9113 3.58867 16.5 8 16.5C12.4113 16.5 16 12.9113 16 8.5C16 4.08867 12.4113 0.5 8 0.5ZM8 15.1667C4.324 15.1667 1.33333 12.176 1.33333 8.5C1.33333 4.824 4.324 1.83333 8 1.83333C11.676 1.83333 14.6667 4.824 14.6667 8.5C14.6667 12.176 11.676 15.1667 8 15.1667Z' fill='%23333333'/%3E%3Cpath d='M8.66667 7.16667H7.33333V12.5H8.66667V7.16667Z' fill='%23333333'/%3E%3Cpath d='M8.66667 4.5H7.33333V5.83333H8.66667V4.5Z' fill='%23333333'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1262_7843'%3E%3Crect width='16' height='16' fill='white' transform='translate(0 0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }

    & {
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: #007ac2;
      text-decoration: underline;
      cursor: pointer;
      text-transform: none;

    }

    & img {
      vertical-align: sub;
    }

    & a {
      margin-left: 10px;
      font-size: 13px !important;
    }
  }

  &__samePrescriptionBothEyes {
    &__checkboxContainer {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      margin: 24px 0px;

      span {
        font-size: 14px;
        font-weight: 300;
        font-family: "Interstate";
      }
    }
  }

  &__innerContainer {
    display: flex;
    font-family: "sofia-pro";

    &__apply {
      display: flex;
      position: relative;
      bottom: 0;
      height: 100%;
      align-items: center;
      justify-content: center;

      @include rxc-mobile-only {
        border-top: 1px solid #e5e5e5;
      }

      &Button {
        position: absolute;
        z-index: 10000;
        right: 0;
        margin-top: 60px;
        height: 40px;
        width: fit-content;
        background-color: #007ac2;
        color: #fff !important;
        text-transform: uppercase;
        font-family: Interstate;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        padding: 12px;
        align-self: center;
        border: none;
        cursor: pointer;
        z-index: 1;
        transition: all 0.2s ease-in-out;

        @include rxc-desktop-only {
          position: fixed;
          bottom: 80px;
          right: 40px;
          margin-bottom: 0px;
          width: 110px;
        }

        @include rxc-mobile-only {
          width: 100%;
        }

        &:hover {
          transform: scale(1.05);
        }

        &_arrowRight {
          border: solid #ffffff !important;
          border-width: 0 1px 1px 0 !important;
          display: inline-block;
          padding: 1px 2px 2px 1px;
          margin-left: 3px;
          vertical-align: middle;
        }
      }
    }

    &_closed {
      font-family: "sofia-pro";
      overflow-y: hidden;
      transition: max-height 0.75s ease-in-out;
      flex-direction: row;
      display: block;
      margin-top: 10px;
      margin-bottom: 20px;
      color: #333333;
      overflow-x: hidden;
      font-size: 14px;
      max-height: 0;

      & * {
        font-size: 14px !important;
      }
    }

    &_open {
      font-family: "sofia-pro";
      height: 100%;
      overflow-y: hidden;
      transition: max-height 0.75s ease-in-out;
      flex-direction: row;
      display: block;

      margin-top: 10px;
      margin-bottom: 20px;
      color: #333333;
      overflow-x: hidden;
      font-size: 14px;

      & * {
        font-size: 14px !important;
      }
    }

    &__title {
      font: 14px "sofia-pro", Verdana, Arial, sans-serif;
      font-weight: 600;
    }

    &__form {
      margin-top: 32px;
      height: 100%;
      display: flex;
      flex-direction: column;

      @include rxc-mobile-only {
        margin-top: 20px;
      }

      &_table {
        font: 12px "Interstate";

        @include rxc-desktop-only {
          display: flex;
          margin-top: 25px;
        }

        @include rxc-small-desktop-only {
          display: flex;
          flex-flow: column wrap;
          flex: 0 0 100%;
        }
      }

      &_leftcol {
        @include rxc-desktop-only {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          margin: 24px 15px 9px 0px;
          flex-direction: column;
        }

        @include rxc-mobile-only {
          display: flex;
          flex-direction: row;
          //justify-content: space-evenly;
          margin: 12px 15px 5px 109px;
          //flex: 0 0 100%;
          align-self: flex-start;
        }

        @include rxc-smaller-mobile-only {
          margin: 12px 15px 5px 91px;
        }
      }

      &_leftlabel {
        font-family: "Interstate";
        font-size: 12px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: #182446;

        @include rxc-mobile-only {
          &:first-child {
            margin-right: 52px;
          }
        }

        @include rxc-smaller-mobile-only {
          &:first-child {
            margin-right: 32px;
          }
        }
      }

      &_col {
        @include rxc-desktop-only {
          flex-flow: column nowrap;
          display: flex;
          justify-content: center;
          margin-right: 16px;
        }

        @include rxc-mobile-only {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          flex: 0 0 100%;
          // justify-content: space-evenly;
          // margin: 24px 15px 9px 0px;
        }

        &.invisible {
          visibility: hidden;

          @include rxc-mobile-only {
            display: none;
          }
        }

        &.maxWidthWithoutADD {
          @include rxc-desktop-only {
            max-width: calc(33.33% - 20px);
          }
        }

        &.maxWidthIsADD {
          @include rxc-desktop-only {
            max-width: calc(25% - 20px);
          }
        }
      }

      &_row {
        margin-bottom: 15px;
        white-space: nowrap;

        @include rxc-mobile-only {
          width: 80px;
          margin-right: 30px;
        }

        @include rxc-smaller-mobile-only {
          width: 60px;
        }
      }

      &_boldsr {
        margin-right: 5px;
        font: 14px "sofia-pro-light", Verdana, Arial, sans-serif;
      }

      &_select {
        width: fit-content;
        direction: ltr;
        text-align-last: left;
        position: relative;

        &_custom {
          border: 1px solid #182446;
          width: 100px;

          @include rxc-smaller-mobile-only {
            width: 86px;
          }
        }

        &__image {
          content: "";
          width: 12px;
          height: 10px;
          position: absolute;
          top: 12px;
          right: 8px;
          background-size: contain;
          background-image: url(https://assets.lenscrafters.com/extra/image/LensCrafters/global/arrow-down-black-ico.svg);
          background-repeat: no-repeat;

          @include rxc-smaller-mobile-only {
            right: -17px;
          }
        }

        &_prescription {
          //border: 1px solid #767676;
          border-radius: 4px;
          //width: 107px;
          position: relative;

          @include rxc-small-mobile-only {
            width: fit-content;
          }

          &__add {
            &::after {
              content: "";
              width: 12px;
              height: 10px;
              position: absolute;
              top: 12px;
              right: 8px;
              background-size: contain;
              background-image: url(https://assets.lenscrafters.com/extra/image/LensCrafters/global/arrow-down-black-ico.svg);
              background-repeat: no-repeat;
              cursor: pointer;
              pointer-events: none;
            }
          }

          &__disabled {
            //border: 1px solid #d8d8d8;
            border-radius: 4px;
            width: 107px;

            @include rxc-small-mobile-only {
              width: 100%;
            }

            & input {
              border: solid 1px transparent;

              &:focus {
                outline: none;
              }
            }
          }

          &__error {
            border: 1px solid #e32118 !important;
            //border-radius: 4px;
            //width: 107px;

            @include rxc-small-mobile-only {
              width: 100%;
            }

            & input {
              border: solid 1px transparent;

              &:focus {
                outline: none;

                &::-webkit-input-placeholder {
                  /* Chrome/Opera/Safari */
                  color: transparent;
                }

                &::-moz-placeholder {
                  /* Firefox 19+ */
                  color: transparent;
                }

                &:-ms-input-placeholder {
                  /* IE 10+ */
                  color: transparent;
                }

                &:-moz-placeholder {
                  /* Firefox 18- */
                  color: transparent;
                }
              }

              &[type="number" i] {
                padding: 1px 2px 1px 20px;
              }
            }
          }

          & input {
            border: solid 1px transparent;

            &:focus {
              outline: none;
            }

            &[type="number" i] {
              padding: 1px 2px 1px 15px;

              @include rxc-small-mobile-only {
                padding: 1px 2px 1px 0;
              }
            }
          }
        }

        & select {
          width: 100px;
          display: flex;
          justify-content: start;
          padding-left: 10px;
          padding-bottom: 2px;
          height: 32px;
          background-color: #ffffff;
          border: solid 1px #182446;
          color: #182446;
          font-family: "Interstate";
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          text-align: left;
          -moz-appearance: none;
          -webkit-appearance: none;
          -ms-appearance: none;

          @include rxc-smaller-mobile-only {
            width: 85px;
          }

          &:after {
            content: "";
            width: 12px;
            height: 10px;
            position: absolute;
            top: 12px;
            right: 8px;
            background-size: 12px;
            background-image: url(https://assets.lenscrafters.com/extra/image/LensCrafters/global/arrow-down-black-ico.svg);
            background-repeat: no-repeat;
            background-position: right;
          }

          /* width */
          &::-webkit-scrollbar {
            width: 4px;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: #d8d8d8;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #767676;
            border-radius: 8px;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #767676;
          }
        }

        @include rxc-desktop-only {
          & #right_eye_axis {
            width: 100px;
            height: 32px;
            padding-left: 10px;
            border: 1px solid #182446;
          }

          & #right_eye_axis:disabled {
            width: 100px;
            height: 32px;
            padding-left: 10px;
            background-color: #ffffff;
            border: 1px solid #dde5ed !important;
          }

          & #left_eye_axis {
            width: 100px;
            height: 32px;
            padding-left: 10px;
            border: 1px solid #182446;
          }

          & #left_eye_axis:disabled {
            width: 100px;
            height: 32px;
            padding-left: 10px;
            background-color: #ffffff;
            border: 1px solid #dde5ed !important;
          }
        }

        @include rxc-mobile-only {
          & #right_eye_axis {
            width: 100px;
            height: 35px;
            padding-left: 10px;
            border: 1px solid #182446;
          }

          & #right_eye_axis:disabled {
            width: 100px;
            height: 35px;
            padding-left: 10px;
            background-color: #ffffff;
            border: 1px solid #dde5ed !important;
          }

          & #left_eye_axis {
            width: 100px;
            height: 35px;
            padding-left: 10px;
            border: 1px solid #182446;
          }

          & #left_eye_axis:disabled {
            width: 100px;
            height: 35px;
            padding-left: 10px;
            background-color: #ffffff;
            border: 1px solid #dde5ed !important;
          }
        }

        @include rxc-smaller-mobile-only {
          & #right_eye_axis {
            width: 85px;
          }

          & #right_eye_axis:disabled {
            width: 85px;
          }

          & #left_eye_axis {
            width: 85px;
          }

          & #left_eye_axis:disabled {
            width: 85px;
          }
        }
      }
    }

    &__warning {
      width: 365px;
      font: 12px "sofia-pro", Verdana, Arial, sans-serif !important;
      line-height: 1.23;
      color: #192c50;
      font-size: 12px !important;
    }
  }

  &__clearButtonError {
    font-family: "sofia-pro";
    margin-right: 35px;
    margin-top: 20px;
    float: right;
    margin: 0;
    font-size: 12px !important;
    height: 24px;
    padding: 0px 22px;
  }
}

.IncompatibleFrame,
.IncompatibleLenses {
  background: #fff;
  color: #222222;
  padding: 16px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 120%;
  margin-bottom: 16px;
  text-align: left;
  border-radius: 8px;
  border: 1px solid #e32118;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Interstate";

  &__text {
    width: 100%;
    text-align: center;

    a {
      text-decoration: underline;
      font-weight: 700;

      &:visited {
        color: black;
      }
    }
  }

  &__button {
    display: inline-block;
    background: #222;
    font-family: "Interstate";
    text-transform: uppercase;
    color: #ffffff;
    padding: 8px 24px;
    margin-top: 16px;
    cursor: pointer;
  }
}

.IssueDateInput {
  width: 50%;
  max-width: 250px;

  label {
    display: block;
    position: relative;

    .input {
      position: relative;
    }

    .input__label {
      position: absolute;
      font-size: 14px;
      background-color: white;
      top: -8px;
      left: 11px;
      padding: 0 4px;
      font-family: "Interstate";
      font-weight: 400;
      margin: 0;
      line-height: 1.2;

      span.orange {
        color: #e1523e;
        font-weight: 600;
      }
    }

    input {
      width: 100%;
      color: #666666;
      padding: 12px 16px;
      font-size: 16px;
      line-height: 150%;
      border: 1px solid #cccccc;
      border-radius: 8px;
      font-family: "Interstate";
      font-weight: 400;

      &.error {
        border: 2px solid #e1523e;
      }
    }
  }

  .SimpleTextError__message {
    padding-left: 16px;

    @include rxc-mobile-only {
      min-width: 190px;
      padding-left: 1px;
    }
  }
}

.MoreOptions {
  &__formWrapper {
    .form_table {
      margin-bottom: 24px;
    }
  }

  &__accordionBtnContainer {
    font-family: "Interstate";
    margin-top: 24px;

    &__accordionBtnClosed {
      font-family: "Interstate";
      text-decoration: underline;
      border: none;
      background: none;
      color: #326fa8;
      cursor: pointer;
      padding: 0;

      &::after {
        content: "";
        width: 6px;
        height: 6px;
        transform: rotate(45deg);
        margin-left: 6px;
        margin-top: 5px;
        border: solid #326fa8;
        border-width: 0 1.5px 1.5px 0;
        display: inline-block;
        padding: 0px;
        position: relative;
        top: -4px;
      }
    }

    &__accordionBtnOpen {
      font-family: "Interstate";
      text-decoration: underline;
      border: none;
      background: none;
      color: #326fa8;
      cursor: pointer;
      padding: 0;

      &::after {
        content: "";
        width: 6px;
        height: 6px;
        transform: rotate(-135deg);
        margin-left: 6px;
        margin-top: 5px;
        border: solid #326fa8;
        border-width: 0 1.5px 1.5px 0;
        display: inline-block;
        padding: 0px;
        position: relative;
        top: 1px;
      }
    }
  }

  &__checkboxContainer {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;

    &_noMoreOptions {
      >div:first-child {
        display: flex;
        align-items: center;
        margin-top: 24px;
        margin-bottom: 24px;
        font-size: 14px;
      }
    }
  }

  &__checkBoxText {
    color: #182446;
    font-family: "Interstate";
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    margin-left: 10px;
  }

  &__checkBoxPrismPrice {
    color: #182446;
    font-family: "Interstate";
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    display: inline-block;
    margin-left: 5px;
  }

  &__commentContainer {
    font-family: "Interstate";
    font-size: 14px;
    font-weight: 300;

    textarea {
      max-width: 100%;
      min-width: 100%;
    }
  }
}

.CustomCheckbox {
  &__checkbox {
    -webkit-appearance: none;
    background-color: white;
    border: 1px solid rgb(118, 118, 118);
    min-width: 13px;
    height: 13px;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    margin-top: 0 !important;


    &.checked {
      background: #182446;

      &:after {
        content: "\2714";
        font-size: 11px;
        position: absolute;
        top: -3.5px;
        left: 1px;
        color: white;
      }
    }

    &:hover {
      border: 1px solid rgb(90, 90, 90);
    }
  }
}

.AgreementCheckbox {
  &__container {
    display: flex;
    align-items: flex-start;
    margin-top: 24px;
    margin-bottom: 24px;

    &.invisible {
      display: none;
    }

  }

  &__text {
    font-family: Interstate;
    margin-left: 10px;
    font-size: 14px;
  }

  &__tooltip {
    display: inline-block;
    height: 16px;
    width: 16px;
    min-width: 16px;
    margin-left: 8px;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z' stroke='%23000000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 16V12' stroke='%23000000' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 8H12.01' stroke='%23000000' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    position: relative;

    &:hover {
      cursor: pointer;

      .AgreementCheckbox__tooltip__body {
        display: block;
      }
    }

    @include rxc-mobile-only {
      display: inline-block;
      margin-top: 10px;
      margin-bottom: -3px;
    }

    @include rxc-desktop-only {
      //margin-top: 4px;
    }

    &__body {
      display: none;
      position: absolute;
      left: -180px;
      background: #f7f6f4;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
      -webkit-border-radius: 8px !important;
      border-radius: 8px;
      border: 1px solid #e5e5e5;
      // outline: 1px solid #e5e5e5;
      font-family: "sofia-pro";
      font-size: 14px;
      font-weight: 400;
      padding: 16px;
      line-height: 150%;
      bottom: 26px;

      @include rxc-desktop-only {
        width: 375px;
      }

      @include rxc-mobile-only {
        top: unset;
        left: -155px;
        width: 90vw;
      }

      &::after {
        content: "";
        position: absolute;
        width: 29px;
        height: 18px;
        bottom: -16px;
        left: 46%;
        z-index: 100;
        background-image: url("data:image/svg+xml,%3csvg width='16' height='8' viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 8L0 0L16 0L8 8Z' fill='%23f7f6f4'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;

        @include rxc-mobile-only {
          left: 148px;
        }
      }
    }
  }
}

.IncompatibleError {
  &__container {
    justify-content: center;
    display: flex;
    flex-direction: column;
    font-family: Interstate;
    vertical-align: middle;
    border: 1px solid red;
    border-radius: 8px;
    padding: 30px 30px 30px 30px;
    align-items: center !important;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    margin-bottom: 24px;
    margin-top: 8px;

    @include rxc-desktop-only {
      min-width: 50%;
      max-width: 80%;
    }

    @include rxc-mobile-only {
      min-width: 70%;
      max-width: 100%;
    }

    &__firstline {
      color: #ff0000;
      margin-bottom: 16px;
      text-align: center;
    }

    &__firstlineClear {
      color: #ff0000;
      margin-bottom: 16px;
      text-align: center;
      max-width: 70%;
    }

    &__alternative {
      margin-bottom: 16px;
      text-align: center;
    }

    &__shopCompatibleBtn {
      width: auto;
      height: 40px;
      border: none;
      border-radius: 18px !important;
      padding: 8px 32px 8px 32px;
      background-color: #222222 !important;
      color: #ffffff !important;
      font: 16px "sofia-pro", Verdana, Arial, sans-serif;
      text-transform: uppercase;
      cursor: pointer;

      @include rxc-mobile-only {
        width: 100%;
        justify-content: center;
        margin-top: 0px;
        font: 14px "sofia-pro", Verdana, Arial, sans-serif;
      }
    }

    &__clearAll {
      height: 40px;
      border-radius: 100px;
      background-color: white;
      color: #222222;
      text-transform: uppercase;
      font-family: "sofia-pro-light";
      font-weight: 600;
      font: 16px "sofia-pro", Verdana, Arial, sans-serif;
      line-height: 1.5;
      text-align: center;
      padding: 8px 32px;
      align-self: center;
      border: solid 1px #222222;
      letter-spacing: 0.5px;
      width: 100%;
      cursor: pointer;
    }
  }
}

.IncompatibleLensType {
  position: relative;
  padding: 13px 46px 7px 32px;
  background: #f2f4f5;
  border-radius: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #222222;

  &__message {
    &::before {
      content: "";
      position: absolute;
      top: 13px;
      left: 8px;
      height: 16px;
      width: 16px;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M8 0C3.589 0 0 3.589 0 8C0 12.411 3.589 16 8 16C12.411 16 16 12.411 16 8C16 3.589 12.411 0 8 0ZM8 15.3333C3.95633 15.3333 0.666667 12.0437 0.666667 8C0.666667 3.95633 3.95633 0.666667 8 0.666667C12.0437 0.666667 15.3333 3.95633 15.3333 8C15.3333 12.0437 12.0437 15.3333 8 15.3333Z' fill='black'/%3e%3cpath d='M8.33333 6H7.66667V12H8.33333V6Z' fill='black'/%3e%3cpath d='M8.33333 4H7.66667V4.66667H8.33333V4Z' fill='black'/%3e%3c/svg%3e");
    }
  }
}

.SimpleTextError {
  &__message {
    font-family: "sofia-pro";
    color: #ff0000;
    margin-top: 10px;
    font-size: 12px;

    @include rxc-mobile-only {
      margin: 10px 0px;
    }
  }
}

.Tooltip {
  z-index: 2147483647;

  &__tooltip {
    font-family: "sofia-pro";
    font-size: 12px;
    width: fit-content;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 13%;
    max-width: 289px;
    padding: 30px;
    opacity: 1 !important;
    background-color: $rxc-white-color-1;
    -moz-box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    letter-spacing: 0.16px;
    color: #222222;

    @include rxc-mobile-only {
      margin-top: 10%;
    }
  }

  &__text {
    display: flex;
    margin-bottom: 8px;
    white-space: pre-line;
    vertical-align: bottom;
  }
}

.rxc-prescription-select-table>div>div {
  margin-right: unset !important;
}

.StepContainer__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: 48px;

  @include rxc-mobile-only {
    margin-right: 0;
  }
}

.CalifornianInput {
  &__toggle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 24px;
    font-family: "Interstate";
    font-size: 14px;
    gap: 16px;
  }

  &__label {
    font-family: "Interstate";
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #182446;
  }

  &__switchContainer {
    display: flex;
    align-items: center;
  }

  &__answerToggle {
    font-family: "Interstate";
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #182446;

    @include rxc-mobile-only {
      &:first-child {
        margin-right: 14px;
      }
    }
  }

  &__toggleDesc {
    margin-bottom: 24px;
    font-family: "Interstate";
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #182446;
  }

  &__loaderContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 160px;
    padding: 24px;
    background-color: #f1f5f8;
    border: 1px solid #dde5ed;
  }

  &__checkboxContainer {
    display: flex !important;
    align-items: center;
    justify-content: center;

    @include rxc-mobile-only {
      position: absolute;
    }
  }

  &__ChooseTitle {
    font-family: "Interstate";
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #182446;
    margin-bottom: 12px;

    @include rxc-desktop-only {
      text-transform: uppercase;
    }

    @include rxc-mobile-only {
      &::first-letter {
        text-transform: capitalize !important;
      }
    }
  }

  &__containerEl {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 24px;
  }

  &__rounded {
    width: 16px;
    height: 16px;
    margin: 4px 0 0;
    border-radius: 68px;
    border: solid 1px #b3bdc3;
    background-color: #007ac2;
    display: flex;
    align-items: center;
    justify-content: center;

    &__selected {
      width: 8px;
      height: 8px;
      border-radius: 68px;
      background-color: #fff;
    }
  }

  &__title {
    font-family: "Interstate";
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #182446;
    margin-left: 8px;

    @include rxc-mobile-only {
      margin-left: 24px;
    }
  }

  &__descEl {
    font-family: "Interstate";
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #182446;
    margin-bottom: 12px;

    @include rxc-mobile-only {
      width: 60%;
      margin-top: 16px;
    }
  }
}

.CalifornianInput__nestedElement .PrescriptionUploadForm__UploadResult__container.succ::before {
  content: "";
  height: 16px;
  width: 16px;
  background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='8' fill='%231E7E34'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.83 11.846a.502.502 0 0 1-.723 0l-2.883-2.97a.806.806 0 0 1 0-1.116l.361-.372a.75.75 0 0 1 1.083 0l1.8 1.854 4.864-5.01a.75.75 0 0 1 1.083 0l.36.371c.3.308.3.808 0 1.116L6.83 11.846z' fill='white'/%3e%3c/svg%3e");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 8px;
}

.CalifornianInput__nestedElement .PrescriptionUploadForm__UploadResult__container.error::before {
  content: "";
  height: 16px;
  width: 16px;
  background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='8' fill='%23CA3300'/%3e%3cpath fill='white' stroke='white' d='m4.936 5.408.471-.472 5.657 5.657-.471.472z'/%3e%3cpath fill='white' stroke='white' d='m10.593 4.936.471.472-5.657 5.657-.471-.472z'/%3e%3c/svg%3e");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 8px;
}