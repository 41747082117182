@import "../../../mixins/global.scss";

.TreatmentFamily {
  &__container {
    background-color: #fff;

    .confirmButton {
      background-color: #0354A6;
      color: #fff;
      padding: 10px 24px;
      border: none;
      cursor: pointer;

      &__container {
        z-index: 1000;
        position: fixed;
        bottom: 90px;
        right: 40px;

        @include rxc-mobile-only {
          bottom: 0;
          left: 0;
          width: 100%;
          padding: 20px;
          display: grid;
          place-items: center;
          background-color: #fff;
        }
      }
    }

    @include rxc-desktop-only {
      padding-bottom: 50px;
    }

  }
}