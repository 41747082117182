@import "../../mixins/global";

.PupillaryDistance {
  &__titleContainer {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    &__title {
      font-family: Interstate;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      margin-bottom: 0;
    }

    &__link {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      font-family: Interstate;
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      cursor: pointer;
      text-decoration: underline !important;
      text-transform: none;
      color: #007ac2 !important;
      max-width: fit-content;

      &::before {
        content: "";
        height: 16px;
        width: 16px;
        min-width: 16px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'%3E%3Cg clip-path='url(%23clip0_1262_7843)'%3E%3Cpath d='M8 0.5C3.58867 0.5 0 4.08867 0 8.5C0 12.9113 3.58867 16.5 8 16.5C12.4113 16.5 16 12.9113 16 8.5C16 4.08867 12.4113 0.5 8 0.5ZM8 15.1667C4.324 15.1667 1.33333 12.176 1.33333 8.5C1.33333 4.824 4.324 1.83333 8 1.83333C11.676 1.83333 14.6667 4.824 14.6667 8.5C14.6667 12.176 11.676 15.1667 8 15.1667Z' fill='%23333333'/%3E%3Cpath d='M8.66667 7.16667H7.33333V12.5H8.66667V7.16667Z' fill='%23333333'/%3E%3Cpath d='M8.66667 4.5H7.33333V5.83333H8.66667V4.5Z' fill='%23333333'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1262_7843'%3E%3Crect width='16' height='16' fill='white' transform='translate(0 0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }
    }
  }

  &__subtitleContainer {
    // margin-bottom: 16px;
    font-family: Interstate;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.5px;
    text-align: left;
    color: #222;

    & a {
      color: #007ac2 !important;
      text-decoration: underline !important;
    }
  }

  &__checkboxContainer {
    display: flex;
    align-items: center;
    margin: 0 !important;
  }

  &__checkBoxText {
    font-family: Interstate;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    margin-left: 10px;
  }

  &__optionsContainer {
    font-family: Interstate;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    &__table {
      margin: 24px 0px;
      font: 14px "Interstate", Verdana, Arial, sans-serif;

      &.single {
        > div {
          height: 40px;

          select {
            background-position: right 10px bottom 12px !important;
          }
        }
      }

      border: 1px solid var(--primary-border, #b3bdc3);
      display: flex;
      max-width: 100%;

      &__leftCol {
        grid-auto-flow: column;
        grid-template-rows: repeat(2, 40px);
      }

      &__leftLabel {
        border-top: 1px solid var(--primary-border, #b3bdc3);
        display: grid;
        place-content: center;
      }

      &__col {
        display: grid;
        grid-auto-flow: column;
        grid-template-rows: repeat(2, 40px);
      }

      &__row {
        &:last-child {
          border-top: 1px solid var(--primary-border, #b3bdc3);
        }

        &:first-child {
          display: grid;
          place-content: center;
          background: var(--primary-background, #eceff0);
        }
      }

      & > div {
        &:not(:last-child) {
          border-right: 1px solid var(--primary-border, #b3bdc3);
        }

        width: 128px;

        & > div {
          padding: 8px;
          width: 100%;
          height: 100%;
        }
      }

      & > div:first-child {
        display: grid;
        place-items: center;
        background: var(--primary-background, #eceff0);
      }
    }

    &__select_container {
      position: relative;
      font-family: Interstate;
      height: 100%;

      input {
        padding-left: 24px !important;
      }

      & select {
        cursor: pointer;
        font-family: Interstate;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        display: flex;
        justify-content: start;
        width: 100% !important;
        padding-left: 10px;
        height: 100%;
        background-color: #ffffff;
        color: #333333;
        border-radius: 4px;
        align-items: center;
        -webkit-appearance: none;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMC44NjIyNTQgMC41Mjg1MTRDMC42MDE5MDUgMC43ODg4NjQgMC42MDE5MDUgMS4yMTA5NyAwLjg2MjI1NCAxLjQ3MTMyTDUuNTI4OTIgNi4xMzc5OUM1Ljc4OTI3IDYuMzk4MzQgNi4yMTEzOCA2LjM5ODM0IDYuNDcxNzMgNi4xMzc5OUwxMS4xMzg0IDEuNDcxMzJDMTEuMzk4NyAxLjIxMDk3IDExLjM5ODcgMC43ODg4NjQgMTEuMTM4NCAwLjUyODUxNUMxMC44NzggMC4yNjgxNjUgMTAuNDU1OSAwLjI2ODE2NSAxMC4xOTU2IDAuNTI4NTE1TDYuMDAwMzMgNC43MjM3OEwxLjgwNTA2IDAuNTI4NTE0QzEuNTQ0NzEgMC4yNjgxNjUgMS4xMjI2IDAuMjY4MTY1IDAuODYyMjU0IDAuNTI4NTE0WiIgZmlsbD0iIzIyMjIyMiIvPgo8L3N2Zz4K) !important;
        background: no-repeat;
        background-position: right 2px bottom 3px !important;
        background-size: 16px 16px;
        border: none;

        @include rxc-small-mobile-only {
          width: 100% !important;
        }

        /* width */
        &::-webkit-scrollbar {
          width: 4px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #d8d8d8;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #767676;
          border-radius: 8px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #767676;
        }
      }

      & select.__DoubleSelect {
        font-family: Interstate;

        .error {
          border-color: #e32118;
          font-family: Interstate;
        }

        @include rxc-small-mobile-only {
          width: 100% !important;
        }
      }
    }
  }

  &__checkboxLabel {
    font-family: Interstate;
    margin: 0 !important;
  }

  &__pdValueNotSelectedWarning {
    color: #d0021b;
    font-family: $rxc-costa-Interstate-font;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    pointer-events: none;
    margin-bottom: 24px;

    &::after {
      content: attr(data-content);
      color: #007ac2;
      text-decoration: underline;
      pointer-events: all;
      line-height: 24px;
    }
  }
}
