@import "../../../mixins/global.scss";

.Review {
  &__productImageContainer {
    @include rxc-desktop-only {
      display: none;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 17px;
    margin-top: 22px;

    &__image {
      display: block;
      align-items: center;
      justify-content: center;
      width: auto;
      max-width: 70%;
      max-height: 100px;
      margin-bottom: 1rem;
    }
  }

  &__includedContainer {
    width: fit-content;
    height: fit-content;
    margin-bottom: 8px;

    &__checkBox {
      display: none !important;
    }

    &__accordion {
      &__open {
        display: block;

        & ul {
          margin: 12px 0;

          & li {
            font-family: $rxc-costa-Interstate-font;
            font-size: 12px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: $rxc-fontColor-costa;
            padding-bottom: 0.3rem;

            &::marker {
              font-size: 0.6rem;
            }
          }
        }
      }

      &__closed {
        display: none;
      }

      &Button {
        font-family: $rxc-costa-Interstate-font;
        font-size: 16px;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: left;
        color: $rxc-blue-costa !important;
        cursor: pointer;
        font-style: normal;
        font-weight: bold;
        line-height: 24px;
        user-select: none;
      }

      &Button.closed::after {
        display: inline-block;
        position: relative;
        top: 1px;
        left: 4px;
        content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNiAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjkwMjMgMS40MDIyNEwxNS40MzEgMC45MzA5MDhMOCA4LjM2MTkxTDAuNTY5IDAuOTMwOTA4TDAuMDk3NjY2NyAxLjQwMjI0TDggOS4zMDQ1OEwxNS45MDIzIDEuNDAyMjRaIiBmaWxsPSIjMDA3QUMyIi8+Cjwvc3ZnPgo=");
      }

      &Button.open::after {
        display: inline-block;
        position: relative;
        top: 1px;
        transform: rotate(180deg);
        transition: 0.3s;
        left: 4px;
        content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNiAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjkwMjMgMS40MDIyNEwxNS40MzEgMC45MzA5MDhMOCA4LjM2MTkxTDAuNTY5IDAuOTMwOTA4TDAuMDk3NjY2NyAxLjQwMjI0TDggOS4zMDQ1OEwxNS45MDIzIDEuNDAyMjRaIiBmaWxsPSIjMDA3QUMyIi8+Cjwvc3ZnPgo=");
      }
    }
  }

  &__topBadge {
    &__desktop {
      &>div {
        background-image: none !important;
        height: 31px !important;
        font-family: $rxc-costa-Interstate-font;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #182446 !important;
        background-color: #dde5ed;
      }

      &>p {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        width: 100%;
        height: 31px;
        font-family: $rxc-costa-Interstate-font;
        font-size: 16px;
        color: #182446 !important;
        background-color: #dde5ed;
      }

      @include rxc-mobile-only {
        display: none;
      }
    }

    &__mobile {
      &>div {
        background-image: none !important;
        height: 25px !important;
        font-family: $rxc-costa-Interstate-font;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #182446 !important;
        background-color: #dde5ed;
      }

      &>p {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        width: 100%;
        height: 25px;
        font-family: $rxc-costa-Interstate-font;
        font-size: 12px;
        background-color: #dde5ed;
      }

      @include rxc-desktop-only {
        display: none;
      }
    }
  }

  &__frameContainer {
    display: flex;
    width: 100%;
    padding-top: 16px;

    &__contentContainer {
      display: flex;
      flex-direction: column;
      width: 100%;

      &__title {
        font-family: $rxc-costa-Interstate-font;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        text-align: left;
        font-size: 16px;
        font-weight: 700;
        color: $rxc-fontColor-costa;
        line-height: 24px;
        display: flex;
        justify-content: space-between;

        @include rxc-desktop-only {
          font-size: 14px;
        }

        @include rxc-mobile-only {
          font-size: 14px;
        }

        @include rxc-smaller-mobile-only {
          font-size: 12px;
        }
      }

      &__name {
        font-family: $rxc-costa-Interstate-font;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        text-align: left;
        font-size: 14px;
        line-height: 16px;
        color: $rxc-fontColor-costa;

        @include rxc-desktop-only {
          padding: 0 0.5rem 4px 0;
          width: 60%;
        }

        @include rxc-mobile-only {
          padding: 0 0.5rem 4px 0;
          font-size: 14px;
        }

        @include rxc-smaller-mobile-only {
          font-size: 12px;
        }
      }
    }

    &__price {
      text-align: right;
      color: $rxc-fontColor-costa;

      @include rxc-desktop-only {
        width: 25%;
      }

      @include rxc-mobile-only {
        width: 20%;
      }

      .StepPrice__container__inner__strikePrice {
        font-size: 12px;
        line-height: unset;

        @include rxc-smaller-mobile-only {
          font-size: 10px;
        }
      }

      .StepPrice__container__inner__price {
        font-size: 16px;
        font-weight: normal;
        line-height: 24px;

        @include rxc-smaller-mobile-only {
          font-size: 14px;
        }
      }
    }
  }

  &__lensContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 16px;

    .Review__frameContainer__contentContainer__title {
      @include rxc-desktop-only {
        margin-bottom: 24px;
      }

      @include rxc-mobile-only {
        margin-bottom: 0px;
      }
    }

    .Review__frameContainer__contentContainer {
      width: unset;

      @include rxc-mobile-only {
        flex-direction: row !important;
        align-items: baseline;
      }
    }
  }

  &__toggle {
    display: flex;
    margin: 0px 0px 16px 0px !important;

    @include rxc-mobile-only {
      margin: 16px 0px 0px !important;
    }

    &__label {
      font-family: $rxc-costa-Interstate-font;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      cursor: pointer;
    }

    &__icon {
      cursor: pointer;
      height: 16px;
      width: 16px;
      margin-left: 8px;
      background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7.99998 12.943L0.195312 5.13798L1.13798 4.19531L7.99998 11.057L14.862 4.19531L15.8046 5.13798L7.99998 12.943Z' fill='black'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }

  &__stepsSeparator {
    min-height: 1px;
    opacity: 0.3;
    background-color: #666666;
  }

  &__priceSummary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include rxc-desktop-only {
      position: absolute;
      bottom: 72px;
      left: 0;
      right: 0;
      min-height: 81px;
      height: fit-content;
      box-shadow: 0 -5px 15px 0 rgb(0 0 0 / 5%);
      background-color: #ffffff;
    }

    @include rxc-mobile-only {
      margin-bottom: 13px;
    }

    &__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 0;
      margin-bottom: 12px;

      @include rxc-desktop-only {
        padding: 0 40px;
      }

      @include rxc-mobile-only {
        margin-top: 12%;
      }

      &__label {
        font-family: $rxc-costa-Interstate-font;
        font-size: 14px;
        color: #1f1f24;
      }

      &__labelTotal {
        display: block;
        font-size: 16px;
        font-family: $rxc-costa-Interstate-font;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 24px;
        text-align: left;
        color: $rxc-fontColor-costa;

        @include rxc-mobile-only {
          display: none;
        }
      }

      &__labelTotalMobile {
        display: none;

        @include rxc-mobile-only {
          display: block;
          font-family: $rxc-costa-Interstate-font;
          font-size: 16px;
          font-stretch: normal;
          font-weight: bolder;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: 0.5px;
          text-align: left;
          color: $rxc-fontColor-costa;
          text-transform: uppercase;
        }
      }
    }

    &__paymentInstallment {
      cursor: pointer;
      display: flex;
      align-self: flex-start;
      font-family: $rxc-costa-Interstate-font;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 16px;
      letter-spacing: normal;
      text-align: left;
      text-decoration: underline;
      color: $rxc-fontColor-costa;

      @include rxc-desktop-only {
        padding: 0 40px;
      }

      @include rxc-mobile-only {
        text-decoration: underline;

        &.marginTop {
          margin-top: 10px;
        }
      }
    }

    &__multipleInstallments {
      cursor: pointer;
      display: flex;
      align-self: flex-start;
      font-family: "sofia-pro-bold";
      text-decoration: underline;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: left;
      color: #222222;

      @include rxc-mobile-only {
        &.marginTop {
          margin-top: 10px;
        }
      }

      @include rxc-desktop-only {
        padding: 0 40px;
      }
    }

    &__separator {
      width: 100%;
      height: 1px;
      opacity: 0.3;
      background-color: #666666;
      margin-bottom: 19px;
    }
  }

  &__warrantyContainer {
    display: flex;
  }

  &__stickyWrapper {
    @include rxc-mobile-only {
      position: fixed;
      bottom: 0;
      background-color: white;
    }
  }

  &__buttonsMobileContainer {
    @include rxc-desktop-only {
      display: none;
    }

    @include rxc-mobile-only {
      position: fixed;
      width: 100%;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      box-shadow: 0 -5px 15px 0 rgba(0, 0, 0, 0.05);
      padding: 16px 0px;
      gap: 16px;

      & button {
        width: 90% !important;
        //margin: 16px 0px !important;
        margin: 0px 0px !important;

        // &:first-child {
        //   margin-bottom: 0px !important;
        // }
      }
    }
  }
}

.StepContainer {
  &__container {
    @include rxc-mobile-only {
      padding-bottom: 128px !important;
    }
  }
}