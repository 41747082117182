@import "../../mixins/global";

.HeaderPrice {
  &__container {
    display: flex;

    @include rxc-mobile-only {
      width: 100%;
      align-self: center;
      justify-content: center;
    }

    &__label {
      font-family: $rxc-costa-Interstate-font;
      font-size: $rxc-sun-default-16-fontSize;
      color: $rxc-fontColor-costa;
      align-self: center;

      p {
        margin: 0 !important;
        font-family: $rxc-costa-Interstate-font !important;
      }

      &__frameLensPrice {
        @include rxc-mobile-only {
          font-family: $rxc-costa-Interstate-font !important;
          font-weight: 600;
          font-size: clamp(
            10px,
            calc(0.625rem + ((1vw - 3.2px) * 7.2727)),
            14px
          );
          min-height: 0vw;
          text-transform: uppercase;
          white-space: nowrap;
        }
      }

      &__price {
        @include rxc-mobile-only {
          font-family: $rxc-costa-Interstate-font !important;
          font-weight: 600;
          font-size: clamp(12px, calc(0.375rem + ((1vw - 3px) * 9.3333)), 14px);
          min-height: 0vw;
          text-transform: uppercase;
        }
      }
    }

    &__value {
      margin-left: 3px;
      box-sizing: border-box;
      font-family: $rxc-costa-Interstate-font;
      font-weight: 700;
      font-size: 18px;

      @include rxc-mobile-only {
        font-family: $rxc-costa-Interstate-font;
        font-weight: 700;
        font-size: 14px;
        align-self: center;
        text-transform: uppercase;
      }
    }
    &__discountvalue {
      margin-left: 4px;
      box-sizing: border-box;
      font-family: $rxc-costa-Interstate-font;
      font-size: $rxc-sun-default-16-fontSize;
      color: $rxc-fontColor-costa;

      @include rxc-mobile-only {
        font-family: $rxc-costa-Interstate-font;
        font-size: 12px;
        align-self: center;
        text-transform: uppercase;
        text-decoration: line-through;
      }
    }
  }

  & .StepPrice__container__inner {
    display: flex;
    align-items: baseline;

    &__strikePrice {
      padding-top: 0;
    }

    &__price {
      font-size: 14px;
    }
  }

  & .Loader__container {
    width: 15px;
    height: 15px;

    & .Loader__icon {
      width: 20px;
      height: 20px;
    }
  }

  &__insurance {
    @include rxc-mobile-only {
      font-family: $rxc-costa-Interstate-font;
      font-size: 16px;
      text-align: left;
      color: $rxc-blue-costa !important;
      margin-top: unset !important;
    }
  }
}
