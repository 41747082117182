@import "../../../mixins/global.scss";

.TransitionColor {
  &__container {
    display: flex;

    @include rxc-desktop-only {
      flex-flow: row wrap;
      row-gap: 32px;
      column-gap: 32px;
    }

    @include rxc-mobile-only {
      overflow-x: auto;
      overflow-y: hidden;
      column-gap: 16px;
    }
  }

  &__btn {
    @include rxc-mobile-only {
      width: 90%;
      margin-left: 0px;
      position: relative;
      padding: 8px 20px;
      font-size: $rxc-sun-default-16-fontSize;
      left: 0px;
      &:hover {
        height: calc(90% + 8px);
        padding: 8px 24px;
      }
    }

    width: fit-content;
    height: 40px;
    margin: 0;
    padding: 12px;
    background-color: $rxc-fontColor-costa;
    border: none;
    position: relative;
    z-index: 1;
    font-size: $rxc-sun-default-14-fontSize;
    font-weight: 700;
    text-align: center;
    color: $rxc-white-color-1 !important;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      height: 48px;
      padding: 12px 16px;
    }

    &_container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;

      @include rxc-mobile-only {
        position: relative;
        top: 30px;
        height: fit-content;
      }
      @include rxc-desktop-only {
        left: 80%;
        transform: translateX(-50%);
        bottom: 95px;
      }
    }
  }

  &__card {
    height: 161px;
    width: 133px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 16px 0px 16px;
    border: solid 1px #d8d8d8;
    background-color: #ffffff;
    cursor: pointer;

    @include rxc-mobile-only {
      width: 120px;
      height: 161px;
    }

    &.selected {
      border: solid 2px $rxc-fontColor-costa;
    }

    &:hover {
      border-color: $rxc-fontColor-costa;
    }

    &.disabled {
      cursor: default;
      border: unset;
      background-color: #f2f4f5;
      opacity: 60%;
    }

    &__img {
      width: 44px;
      height: 57px;
      margin: 0 17px 8px;
    }
    &__text {
      flex-grow: 0;
      font-family: $rxc-costa-Interstate-font;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: 0.5px;
      text-align: left;
      color: $rxc-fontColor-costa;
      margin: 0px;
      white-space: nowrap;

      & p {
        margin: 0;
      }
    }
  }
}

.TransitionColor__container .SkipContainer {
  &__container {
    display: inherit;
    flex-flow: inherit;
    row-gap: inherit;
    column-gap: inherit;

    @include rxc-mobile-only {
      overflow-x: auto;
      overflow-y: hidden;
    }
  }
}

.StepContainer__container {
  @include rxc-mobile-only {
    min-height: 60vh;
    height: fit-content;
  }
}
