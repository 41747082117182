@import "../../mixins/global.scss";

.LensColorCard {
  &__container {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    left: -10px;
    width: 105%;

    &__card {
      width: 133px;
      min-width: 133px;
      height: 185px;
      min-height: 185px;
      align-self: stretch;
      flex-grow: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 8px 0px 16px;
      margin-top: 16px;
      margin-bottom: 4px;
      border: solid 2px #dde5ed;
      background-color: $rxc-white-color-1;
      cursor: pointer;

      &.disabled {
        cursor: default;
        border: unset;
        background-color: #f2f4f5;
        opacity: 60%;
      }

      @include rxc-mobile-only {
        margin-top: 0px;
        width: 133px;
        min-width: 133px;
        margin-right: 16px;
        padding: 8px 0px 16px;
      }

      &.promoBadge {
        height: 205px;
        min-height: 205px;
      }

      div {
        font-family: $rxc-costa-Interstate-font !important; //otherwise it gets overwritten by the csm
      }

      &.selected {
        border: solid 2px $rxc-fontColor-costa;
      }

      &:hover {
        border-color: $rxc-fontColor-costa;
      }

      &__img {
        width: auto;
        max-width: 90px;
        height: 74px;
        min-height: 74px;
        margin: 0;
      }

      &__text {
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-width: 100%;
        font-family: $rxc-costa-Interstate-font;
        font-size: 14px;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
      }

      &__colortype {
        flex-grow: 0;
        font-family: $rxc-costa-Interstate-font;
        font-size: $rxc-sun-default-12-fontSize;
        text-align: left;
        color: #222 !important;
        white-space: nowrap;
        margin-bottom: 2px;

        @include rxc-mobile-only {
          margin-bottom: 10px;
        }
      }

      &__promoBadge {
        font: 12px $rxc-costa-Interstate-font;
        margin-right: 20px;
        font-weight: 700;
        color: $rxc-green-costa !important;

        @include rxc-mobile-only {
          font-size: 0px;
        }
      }

      &__price {
        flex-grow: 0;
        font-family: $rxc-costa-Interstate-font;
        font-size: $rxc-sun-default-12-fontSize;
        text-align: left;
        color: #222 !important;
        white-space: nowrap;
        display: block;
        margin-top: auto;

        &.promoBadge {
          margin-top: unset !important;
        }

        & .StepPrice__container {
          @include rxc-desktop-only {
            position: relative;
          }
        }

        & .StepPrice__container__inner__price {
          font-size: 14px !important;
        }

        & .StepPrice__container__inner__labelContainer {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          -webkit-flex-wrap: wrap;
          flex-wrap: wrap;

          /* margin-top: 8px; */
          & .StepPrice__container__inner__GVPLabel {
            margin-right: 0 !important;
          }
        }

        & .StepPrice__container__insuranceText {
          display: none;
        }
      }

      &__promoBadge {
        margin-top: auto;

        &>div {
          margin-right: 0;
          margin-top: 8px;
        }
      }
    }
  }
}