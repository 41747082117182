@import "../../../mixins/global.scss";

.Thickness {
  &__container {
    @include rxc-mobile-only {
      margin-top: 6px;
    }
    margin-bottom: 10px;
    &__separator {
      border: 1px solid $rxc-grey-costa;
      margin: -4px 0 18px 0;
      @include rxc-mobile-only {
        margin: 0 0 18px 0;
      }
    }
    &__title {
      .StepTitle__container {
        padding: 6px 0px 24px 0;
        &__title,
        &__titleMobile {
          font-family: "Interstate";
          font-size: 32px;
          line-height: 1.19;
        }
        &__titleMobile {
          font-size: 20px;
          letter-spacing: normal;
        }
        &__title {
          letter-spacing: -1.6px;
        }
      }
    }
  }
  &__filterPill {
    background-color: $rxc-white-color-1;
    max-height: fit-content;
    cursor: pointer;
    max-height: fit-content;
    text-transform: capitalize;
    margin: 0 16px 0 0;
    padding: 8px 16px;
    border-radius: 100px;
    border: solid 1px $rxc-grey-separator;
    font-size: $rxc-sun-default-12-fontSize;
    white-space: nowrap;

    &.selected {
      cursor: pointer;
      text-transform: capitalize;
      padding: 8px 16px;
      margin: 0 16px 0 0;
      color: $rxc-white-color-1;
      background-color: $rxc-grey2-sun-default;
      border-radius: 100px;
      border: solid 1px $rxc-grey2-sun-default;
      font-size: $rxc-sun-default-12-fontSize;
    }
  }
}
