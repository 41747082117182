@import "../../mixins/global";

.InsuranceErrorPopup {
  &__container {
    display: block;
    padding: 0;
    border-radius: 8px;
    box-shadow: 0 5.9px 11.8px 0 rgba(96, 97, 112, 0.16),
      0 0 2.9px 0 rgba(40, 41, 61, 0.04);
    background-color: #ffffff;

    &__overlay {
      position: absolute;
      background-color: rgba(51, 51, 51, 0.6);
      top: 0;
      left: 0;
      z-index: 100;
      width: 100%;
      height: 100%;
    }
    &__popup {
      border-radius: 8px;
      box-shadow: 0 5.9px 11.8px 0 rgba(96, 97, 112, 0.16),
        0 0 2.9px 0 rgba(40, 41, 61, 0.04);
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 472px;
      height: fit-content;
      padding: 40px 24px;
      text-align: center;
      overflow: hidden;
      transform: translateX(-50%) translateY(-50%);
      z-index: 1000;
      @include rxc-mobile-only {
        width: 85%;
        padding: 30px 22px 26px 22px;
      }
    }
    &__title {
      color: #1f1f24;
      margin-bottom: 20px;
      line-height: 1.33;
      letter-spacing: 0.25px;
      text-transform: uppercase;
      font-size: 14px;
      font-family: $rxc-costa-Interstate-font;
    }
    &__subtitle {
      text-align: center;
      line-height: 1.33;
      letter-spacing: 1.25px;
      color: #222222;
      font-size: 12px;
      font-family: $rxc-costa-Interstate-font;
      margin-bottom: 18px;
    }
    &__button {
      background-color: #182446;
      border: none;
      border-radius: 0px;
      width: 40%;
      height: 40px;
      color: #fff;
      text-transform: uppercase;
      cursor: pointer;
      font-size: 16px;
      font-family: $rxc-costa-Interstate-font;

      @include rxc-mobile-only {
        font-size: 12px;
        width: 100%;
        border-radius: 0px;
      }
      &:hover {
        width: calc(40% + 8px);
        height: 48px;
      }
    }
  }
}
