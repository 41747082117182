.UploadTableReadOnly {
  &__container {
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border: 1px solid #b3bdc3;
    transition: all 0.2s ease-in-out;
    margin: 16px 0px;

    &__previewLabel {
      color: #182446;
      font-family: "Interstate";
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      text-transform: uppercase;
    }
    &__fileInfo {
      display: flex;

      &__fileName {
        color: #182446;
        font-family: "Interstate";
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
      }
      &__uploadDifferent {
        color: #0354a6;
        font-family: "Interstate";
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 22px;
        text-decoration-line: underline;
      }
    }
  }
}
