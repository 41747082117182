@import "../../mixins/global.scss";

.LensPreviewFallback {
  &__btn {
    @include rxc-mobile-only {
      width: 90%;
      margin-left: 0px;
      position: relative;
      background-color: $rxc-grey2-sun-default;
      padding: 8px 20px 8px 20px;
      font-size: $rxc-sun-default-16-fontSize;
      font-family: $rxc-sun-default-sofiaBold-font;
      left: 0px;
    }

    width: fit-content;
    height: 40px;
    width: 110px;
    margin: 0;
    padding: 10px 0;
    border-radius: 100px;
    background-color: $rxc-grey2-sun-default;
    border: none;
    position: relative;
    font-size: $rxc-sun-default-14-fontSize;
    font-family: $rxc-sun-default-sofia-font;
    font-weight: 500;
    text-align: center;
    color: $rxc-white-color-1;
    text-transform: uppercase;
    cursor: pointer;

    &_title {
      &__wrapper {
        display: flex;
        flex: 1;
        align-items: flex-end;
        justify-content: center;
        flex-direction: column;
        margin-right: 20px;
        min-width: 235px;

        &__name {
          margin-bottom: 10px;
          font-family: "sofia-pro";
          font-weight: 600;
          text-align: left;
        }

        &__desc {
          text-align: right;
          font-family: "sofia-pro";
          font-weight: 400;
          font-size: 12px;
        }
      }
    }

    &_image {
      height: 80px;
      margin-top: 7px;
    }

    &_container {
      display: flex;
      justify-content: center;
      align-items: center;

      @include rxc-mobile-only {
        bottom: 22%;
        left: 50%;
        position: relative;
        margin-top: 11.5px;
        transform: translate(-50%, 0);
      }

      @include rxc-desktop-only {
        position: absolute;
        right: 3%;
        bottom: 12% !important;
        transform: translateX(-50%);
        z-index: 1000;
      }

      &.borderOutline {
        @include rxc-mobile-only {
          padding: 2px;
          padding-bottom: 20px;
          border: 1px solid $rxc-fontColor-costa;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          border-radius: 0px;
          margin-top: 16px;
          margin-bottom: 50px;
        }
      }

      &__detailBox {
        display: flex;
        flex-direction: column;
        word-wrap: break-word;
        margin: 16px 20px 8px 20px;
        word-break: break-word;
        align-self: flex-start;

        @include rxc-desktop-only {
          position: absolute;
          bottom: 80%;
          right: -3%;
          width: 280px;
        }

        @include rxc-mobile-only {
          margin: 16px 0 8px 0;
        }

        &__content {
          display: flex;
          flex-direction: column;
          position: relative;

          @include rxc-desktop-only {
            &::before {
              content: "";
              position: absolute;
              top: 6px;
              left: -14.5px;
              width: 1px;
              height: 100%;
              background-color: #222;
            }

            &::after {
              content: "";
              position: absolute;
              left: -19px;
              bottom: -7px;
              border-radius: 50%;
              width: 10px;
              height: 10px;
              background-color: #222;
            }
          }

          &__textContainer {
            display: flex;
            line-height: 24px;
            margin-bottom: 4px;
            font-size: 14px;
            flex-direction: column;
            font-size: $rxc-sun-default-14-fontSize;
            font-family: $rxc-sun-default-sofia-font;
            text-align: left;

            &__color {
              font-weight: bold;
            }

            &__message {
              line-height: 17px;
              font-weight: normal;
              width: 80%;
              margin: 10px 0px;
            }

            &__text {
              margin-right: 8px;
            }

            &__inner {
              font-family: $rxc-default-sofiaBold-font;

              @include rxc-desktop-only {
                max-width: 60%;
              }

              @include rxc-small-mobile-only {
                max-width: 95%;
                word-break: break-word;
              }
            }
          }

          &__textContainerRight {
            display: flex;
            line-height: 24px;
            margin-bottom: 4px;
            font-size: 14px;
            flex-direction: row;
            font-size: $rxc-sun-default-14-fontSize;
            font-family: $rxc-sun-default-sofia-font;
            text-align: left;

            &__color {
              font-weight: bold;
            }

            &__message {
              line-height: 17px;
              font-weight: normal;
              width: 80%;
              margin: 10px 0px;
            }

            &__text {
              margin-right: 8px;
            }

            &__inner {
              font-family: $rxc-default-sofiaBold-font;

              @include rxc-desktop-only {
                max-width: 100%;
              }

              @include rxc-small-mobile-only {
                max-width: 95%;
                word-break: break-word;
              }
            }
          }

          &__imageContainer {
            & img {
              max-width: unset;
              width: 110px;
              height: 100px;
            }
          }

          &__wrapper {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            flex-direction: column;

            @include rxc-desktop-only {
              margin-bottom: 10px;
            }
          }
        }
      }

      &__doubleLayout {
        display: flex;
        flex-direction: row;
        word-wrap: break-word;
        margin: 16px 20px 8px 20px;
        word-break: break-word;
        align-self: flex-start;

        @include rxc-desktop-only {
          position: absolute;
          bottom: 85%;
          min-width: 280px;
        }

        @include rxc-mobile-only {
          margin: 16px 0 8px 0;
        }

        &__wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;

          & div {
            height: 100%;
            align-self: normal;
          }

          &__separator {
            width: 1px;
            align-self: normal;
            background-color: #000000;
            position: relative;
            margin-right: 20px;

            &::after {
              content: "";
              position: absolute;
              left: -4.5px;
              bottom: -7px;
              border-radius: 50%;
              width: 10px;
              height: 10px;
              background-color: #222;
            }
          }
        }

        &__right {
          display: flex;
          align-items: flex-start;
          flex-direction: column;

          &__colorCategory {
            font-family: "sofia-pro";
            font-weight: 600;
            margin: 0px 0px 15px !important;
          }

          &__desc {
            font-family: "sofia-pro";
            font-weight: 400;
            font-size: 12px;
            text-align: left;
            margin: 0px 0px 15px !important;
          }

          & div {
            // margin: 5px 0px;
          }
        }
      }
    }
  }
}