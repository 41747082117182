@import "../../mixins/global";

@keyframes loading-animation {
  0% {
    background-position-x: 100%;
  }

  to {
    background-position-x: 0;
  }
}

.BottomBar {
  display: flex;
  flex-flow: column nowrap;
  width: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 72px;
  background-color: #ffffff;
  z-index: 1;

  &__hide {
    display: none;
  }

  &__legalBanner {
    display: flex;
    flex-flow: column nowrap;
    width: 50%;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 102px;
    background-color: #ffffff;
    z-index: 1;

    @include rxc-mobile-only {
      display: none;
    }
  }

  .StepPrice__container__inner__GVPLabel {
    display: none;
  }

  @include rxc-mobile-only {
    display: none;
  }

  &::before {
    content: "";
    height: 1px;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 15px;
    background-color: #dde5ed;
  }

  &__container {
    display: flex;
    align-items: center;
    height: 100%;
    border-bottom: 0px;
    justify-content: space-between;
    padding: 0 40px;

    &__containerLastPage {
      justify-content: space-between;
      margin-left: 4px;
      margin-right: 4px;

      .savings {
        display: none;
      }
    }

    &__noInsurance {
      justify-content: flex-end;
    }

    &__left {
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-start;

      &__wrapper {
        display: flex;
        flex-direction: column;
      }

      &__insuranceWrapper {
        display: flex;
      }

      &__insuranceIcon {
        height: 24px;
        width: 24px;
        min-width: 24px;
        margin-left: 8px;
        cursor: pointer;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z' stroke='%23287E4E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 16V12' stroke='%23287E4E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 8H12.01' stroke='%23287E4E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }

      &__removeInsuranceLabel {
        font: 14px $rxc-costa-Interstate-font, Verdana, Arial, sans-serif;
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
        margin-top: 3px;
        width: fit-content;
      }

      &__insurance {
        text-align: right;
        box-sizing: border-box;
        display: flex;
        color: $rxc-blue-costa;
        font: 14px $rxc-costa-Interstate-font;
        font-weight: 700;
        border: none;
        background: none;
        text-decoration: underline;
        cursor: pointer;
      }

      &__insuranceEnabled {
        font: 16px $rxc-costa-Interstate-font;
        color: $rxc-green-costa;
      }

      &__addInsuranceButton {
        height: 40px;
        background-color: white;
        color: $rxc-fontColor-costa;
        text-transform: uppercase;
        font-family: $rxc-costa-Interstate-font;
        font-weight: 800;
        font-size: 16px;
        line-height: 1.2;
        text-align: center;
        padding: 0 32px;
        align-self: center;
        border: solid 1px $rxc-fontColor-costa;
        letter-spacing: 0.5px;
        cursor: pointer;

        @include rxc-desktop-only {
          width: fit-content;
        }

        @include rxc-mobile-only {
          width: 100%;
          margin: 24px 0;
        }

        &:hover {
          padding: 0 36px;
          height: 48px;
        }
      }
    }

    &__right {
      font-family: $rxc-costa-Interstate-font;
      font-weight: 700;
      font-size: 20px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 0.5px;
      margin-right: 0px;
      margin-left: 20px;
      width: fit-content;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      justify-content: flex-end;

      &__noInsurance {
        margin-right: 0;
      }

      &__legalBanner {
        margin: 4px 0 4px 6px;
        font-family: $rxc-costa-Interstate-font;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.5px;
        text-align: right;
        color: #666;
      }

      &__addToBagButton {
        height: 40px;
        width: fit-content;
        background-color: $rxc-fontColor-costa;
        color: #fff !important;
        text-transform: uppercase;
        font-family: $rxc-costa-Interstate-font;
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        padding: 0 12px;
        align-self: center;
        border: solid 1px $rxc-fontColor-costa;
        letter-spacing: 0.5px;
        cursor: pointer;

        @include rxc-mobile-only {
          width: 100%;
        }

        &:hover {
          padding: 12px 16px;
          height: 48px;
        }

        &.loading {
          background-image: linear-gradient(90deg,
              #5d667e,
              #5d667e 50%,
              #182446 0,
              #182446);
          background-repeat: no-repeat;
          background-size: 200% 100%;
          background-position: 0 100%;
          animation: loading-animation 1.5s infinite;
          animation-timing-function: linear;
          opacity: 1;
        }
      }
    }
  }
}