$rxc-white-color-1: #ffffff;
$rxc-white-color-2: #f2f2f2;
$rxc-white-color-3: #f5f5f5;
$rxc-black-color-1: #000000;
$rxc-black-color-2: #222222;
$rxc-grey-color-1: #f6f6f6;
$rxc-grey-color-2: #e1e1e1;
$rxc-grey-color-3: #333333;
$rxc-grey-color-4: #d3d3d3;
$rxc-grey-color-5: #d7d7d7;
$rxc-grey-color-6: rgba(51, 51, 51, 0.6);
$rxc-grey-color-7: #666666;
$rxc-grey-color-8: rgba(255, 255, 255, 0.8);
$rxc-grey-color-9: #d8d8d8;
$rxc-grey-color-10: #4a4a4a;
$rxc-grey-color-11: #cccccc;
$rxc-grey-color-12: #888888;
$rxc-green-color-1: #2c7d50;
$rxc-blue-color-1: #326fa8;
$rxc-blue-color-2: #192c50;
$rxc-blue-color-3: #367ebd;
$rxc-blue-color-4: #88abba;
$rxc-blue-color-5: #3e626f;
$rxc-blue-color-6: #bdd1e3;
$rxc-red-color-1: #d0021b;
$rxc-red-color-2: #e1251b;
$rxc-pink-color-1: #e71d86;
@import "../../mixins/global";

// Prescription select
@mixin rxc-prescription-select-min-height {
  min-height: 30px;
}

.rxc-prescription-select {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
  min-width: 85px;

  @include rxc-smaller-mobile-only {
    min-width: 80px;
  }

  @include rxc-prescription-select-min-height;
  margin: 0;
  padding: 0 8px;
  background-color: $rxc-white-color-1;
  cursor: pointer;

  input {
    width: 85%;
  }

  @mixin rxc-prescription-select-border {
    border: 0.5px solid $rxc-grey-color-10;
  }

  &.rxc-prescription-select-border {
    @include rxc-prescription-select-border;
  }

  // &.rxc-prescription-select-error {
  //   border: 2px solid $rxc-red-color-1;
  //   border-radius: 2px;
  // }

  &.rxc-prescription-select-warning {
    border: 2px solid $rxc-blue-color-2;
    border-radius: 2px;
  }

  &.rxc-prescription-select-arrow {
    border-radius: 4px;

    &::after {
      content: "";
      width: 16px;
      height: 16px;
      background-size: contain;
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M7.99998 12.943L0.195312 5.13798L1.13798 4.19531L7.99998 11.057L14.862 4.19531L15.8046 5.13798L7.99998 12.943Z" fill="%23182446"/></svg>');
      background-repeat: no-repeat;
    }

    &.open {
      &::after {
        transform: rotate(180deg);
        top: 3px;
      }
    }
  }

  .rxc-prescription-select-value {
    flex: 1 1 auto;
    margin: 0;
    padding: 0;
    border: 0;
    cursor: inherit;
    font-family: Interstate;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #182446 !important;

    &:focus-visible {
      outline: 0;
    }
  }

  .rxc-prescription-select-table {
    display: flex;
    flex-flow: column nowrap;
    position: absolute;
    top: 100%;
    left: -1px;
    width: 101%;
    height: 250px;
    background-color: $rxc-white-color-1;
    @include rxc-prescription-select-border;
    border-color: #b3bdc3;
    overflow-x: auto;
    z-index: 1;

    &>div:first-child {
      &>div:first-child {
        top: 0 !important;
        left: 0 !important;
        bottom: -5px !important;
        font-size: 12px;
        margin-bottom: 0px !important;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }

        @include rxc-mobile-only {
          bottom: 10px !important;
          right: -5px !important;

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }

    .rxc-prescription-select-table-row,
    .rxc-prescription-select-table-col {
      display: flex;
      flex: 1 1 auto;
    }

    .rxc-prescription-select-table-row {
      flex-flow: row nowrap;
      @include rxc-prescription-select-min-height;

      &>.rxc-prescription-select-table-col {
        border-right: 1px solid $rxc-grey-color-9;
      }

      &>.rxc-prescription-select-table-col~.rxc-prescription-select-table-col {
        border-right: 0;
      }
    }

    .rxc-prescription-select-table-col {
      flex-flow: column nowrap;
    }

    .rxc-prescription-select-table-option {
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover,
      &:focus {
        background-color: $rxc-blue-color-6;
      }
    }
  }

  .rxc-prescription-select-track {
    width: 4px !important;
    right: 0;
    height: 100%;
    background-color: $rxc-grey-color-11;

    .rxc-prescription-select-thumb {
      border-radius: 5px;
      background-color: $rxc-grey-color-12;
    }
  }

  .rxc-prescription-select-buttons {
    display: flex;

    button {
      padding: 4px 16px;
      border: none;
      margin: 0;
      background: #182446;
      color: #fff;
      cursor: pointer;
      z-index: 100;
      flex: 1;
      font-size: 16px;

      &:first-child {
        border-right: 1px solid black;
      }
    }
  }
}