@import "../../mixins/global";

.BottomBarTotalPrice {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10px 0;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0;

  &__pricerow {
    align-self: flex-end;
    align-items: baseline;
    display: flex;
    width: max-content;
    align-items: center;

    &__label {
      font-family: $rxc-costa-Interstate-font;
      font-weight: bold;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
      color: #182446;

      p {
        margin: 0 !important;
      }
    }
    &__price {
      margin-left: 10px;
    }
    &__green {
      color: #247534;
    }
  }

  &__gstrow {
    display: none;
  }
}
