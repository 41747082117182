@import "../../mixins/global";

.StepPriceBadge {
  &__container {
    font: 12px $rxc-costa-Interstate-font;
    margin-right: 20px;
    font-weight: 700;
    color: $rxc-green-costa !important;
    line-height: 24px;
  }
}
