@import "../../mixins/global";

.SelectFromMyAccount {
  font-family: $rxc-sun-default-sofia-font;

  @include rxc-mobile-only {
  }
  &__subtitle {
    margin: 0;
    margin-bottom: 24px;
    font-size: 14px;
    white-space: pre-line;
    display: inline;
    font-family: "sofia-pro";
    @include rxc-mobile-only {
      white-space: normal;
      margin-bottom: 20px;
    }
  }

  &__add {
    color: #557b92;
    display: inline;
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;

    @include rxc-mobile-only {
      display: block;
    }
  }
}
