@import "../../mixins/global";

.StepContainer {
  padding-bottom: 5px;

  &__container {
    @include rxc-desktop-only {
      margin: 0 60px 0 40px;
    }

    &:last-child {
      @include rxc-desktop-only {
        padding-bottom: 24px;
      }
    }

    &__blur {
      filter: opacity(25%);
    }
  }
}

.learnMoreButton {
  cursor: pointer;
  width: fit-content;
}
